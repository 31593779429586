import { ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, Injector, OnInit, ViewContainerRef } from '@angular/core';
import { ConfirmDialogComponent } from '@components/dialog/confirm-dialog/confirm-dialog.component';
import { InputDialogComponent } from '@components/dialog/input-dialog/input-dialog.component';
import { Config, Platform } from '@ionic/angular';
import { ShowConfirmDialogArgs, ShowInputDialogArgs } from '@services/dialog/dialog-service.interfaces';
import { DialogService } from '@services/dialog/dialog.service';
import { LoadingService } from '@services/loading/loading.service';
import { PlatformService } from '@services/platform/platform.service';
import { register } from 'swiper/element/bundle';

register();

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

    // region Initialization

    constructor(
        private injector: Injector,
        private platform: Platform,
        private config: Config,
        private platformService: PlatformService,
        private loading: LoadingService,
        private dialog: DialogService,
        private changeDetectorRef: ChangeDetectorRef,
        private viewContainer: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver
    ) {
        this.init();
    }

    async init(): Promise<any> {
        await this.platform.ready();

        if (this.platformService.isMobile) {
            document.documentElement.classList.add('mobile');
        }

        await this.loading.init();
    }

    ngOnInit(): void {
        this.dialog.showConfirmDialog.subscribe(args => this.showConfirmDialog(ConfirmDialogComponent, args));
        this.dialog.showInputDialog.subscribe(args => this.showInputDialog(InputDialogComponent, args));
    }

    // endregion

    // region Methods

    showConfirmDialog(dialogComponent: new(dialog: DialogService) => ConfirmDialogComponent, args: ShowConfirmDialogArgs): ComponentRef<ConfirmDialogComponent> {
        this.viewContainer.clear();

        let dialogComponentFactory = this.componentFactoryResolver.resolveComponentFactory(dialogComponent);
        let dialogComponentRef = this.viewContainer.createComponent(dialogComponentFactory);

        dialogComponentRef.instance.init(args);

        dialogComponentRef.instance.close.subscribe(() => {
            dialogComponentRef.destroy();
        });

        return dialogComponentRef;
    }

    showInputDialog(dialogComponent: new(dialog: DialogService) => InputDialogComponent, args: ShowInputDialogArgs): ComponentRef<InputDialogComponent> {
        this.viewContainer.clear();

        let dialogComponentFactory = this.componentFactoryResolver.resolveComponentFactory(dialogComponent);
        let dialogComponentRef = this.viewContainer.createComponent(dialogComponentFactory);

        dialogComponentRef.instance.init(args);

        dialogComponentRef.instance.close.subscribe(() => {
            dialogComponentRef.destroy();
        });

        return dialogComponentRef;
    }

    // endregion
}
