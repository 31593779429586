import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dictionary, IDictionary, IEnumerable, IKeyValue } from 'linq-collections';
import { Subject } from 'rxjs';
import { BackendService } from '../backend/backend.service';
import { HttpCache } from '../backend/http-cache';
import { RequestNames } from '../backend/requests';
import { Entities, Entity, RecordCreatedMessage, RecordDeletedMessage, RecordMessage, RecordUpdatedMessage } from './cache-manager.interfaces';

@Injectable({ providedIn: 'root' })
export class CacheManagerService {

    // region Fields and Initialization

    private entities: IDictionary<string, Entity>;

    recordCreatedEvent: Subject<RecordCreatedMessage>;
    recordCreatedFilteredEvent: Subject<RecordMessage>;
    recordUpdatedEvent: Subject<RecordUpdatedMessage>;
    recordUpdatedFilteredEvent: Subject<RecordUpdatedMessage>;
    recordDeletedEvent: Subject<RecordDeletedMessage>;

    constructor(
        private backend: BackendService,
        private httpCache: HttpCache
    ) {
        // TODO add additional entities here
        this.entities = new Dictionary<string, Entity>([
            { key: Entities.UserManagement, value: { listRequest: RequestNames.GetUsers, getRequest: RequestNames.GetUser, selectRequest: RequestNames.SelectUsers, filteredRequest: true, linkedRequests: [RequestNames.GetCalendarEmployees] } },
            { key: Entities.UserSetting, value: { listRequest: null, getRequest: RequestNames.GetUserSetting, selectRequest: null, filteredRequest: false } },
            { key: Entities.UserProfile, value: { listRequest: null, getRequest: RequestNames.GetUserProfile, selectRequest: null, filteredRequest: false } },
            { key: Entities.FleetProfile, value: { listRequest: null, getRequest: RequestNames.GetFleetManagerProfile, selectRequest: null, filteredRequest: false } },
            { key: Entities.Appointments, value: { listRequest: RequestNames.GetAppointments, getRequest: RequestNames.GetAppointment, selectRequest: RequestNames.SelectAppointments, filteredRequest: true, linkedRequests: [RequestNames.GetCalendarAppointments] } },
            { key: Entities.Calendar, value: { listRequest: RequestNames.GetCalendarAppointments, getRequest: RequestNames.GetAppointment, selectRequest: null, filteredRequest: false } },
            { key: Entities.FacilityTypes, value: { listRequest: RequestNames.GetFacilityTypes, getRequest: RequestNames.GetFacilityType, selectRequest: RequestNames.SelectFacilityTypes, filteredRequest: true } },
            { key: Entities.Facilities, value: { listRequest: RequestNames.GetFacilities, getRequest: RequestNames.GetFacility, selectRequest: RequestNames.SelectFacilities, filteredRequest: true } },
            { key: Entities.Service, value: { listRequest: null, getRequest: RequestNames.GetService, selectRequest: null, filteredRequest: false } },
            { key: Entities.ServiceSetting, value: { listRequest: null, getRequest: RequestNames.GetServiceSetting, selectRequest: null, filteredRequest: false } },
            { key: Entities.FleetSetting, value: { listRequest: null, getRequest: RequestNames.GetFleetManagerSetting, selectRequest: null, filteredRequest: false } },
            { key: Entities.RepairedMakes, value: { listRequest: null, getRequest: RequestNames.GetRepairedMakes, selectRequest: null, filteredRequest: false } },
            { key: Entities.ServicePartners, value: { listRequest: RequestNames.GetServicePartners, getRequest: RequestNames.GetServicePartner, selectRequest: null, filteredRequest: true } },
            { key: Entities.AppointmentWorksheets, value: { listRequest: RequestNames.GetWorksheets, getRequest: RequestNames.GetWorksheet, selectRequest: RequestNames.SelectWorksheets, filteredRequest: true, linkedRequests: [RequestNames.GetCalendarAppointments, RequestNames.GetAppointment] } },
            { key: Entities.Worksheets, value: { listRequest: RequestNames.GetWorksheets, getRequest: RequestNames.GetWorksheet, selectRequest: RequestNames.SelectWorksheets, filteredRequest: true, linkedRequests: [RequestNames.GetCalendarAppointments, RequestNames.GetAppointment] } },
            { key: Entities.FinishedWorksheets, value: { listRequest: RequestNames.GetWorksheets, getRequest: RequestNames.GetWorksheet, selectRequest: RequestNames.SelectWorksheets, filteredRequest: true } },
            { key: Entities.Customers, value: { listRequest: RequestNames.GetCustomers, getRequest: RequestNames.GetCustomer, selectRequest: RequestNames.SelectCustomers, filteredRequest: true } },
            { key: Entities.ServiceCars, value: { listRequest: RequestNames.GetServiceCars, getRequest: RequestNames.GetServiceCar, selectRequest: RequestNames.SelectServiceCars, filteredRequest: true } },
            { key: Entities.Cars, value: { listRequest: RequestNames.GetCars, getRequest: RequestNames.GetCar, selectRequest: RequestNames.SelectCars, filteredRequest: true } },
            { key: Entities.FleetCustomers, value: { listRequest: RequestNames.GetFleetCustomers, getRequest: RequestNames.GetFleetCustomer, selectRequest: null, filteredRequest: true } }
        ]);

        this.recordCreatedEvent = new Subject<RecordCreatedMessage>();
        this.recordCreatedFilteredEvent = new Subject<RecordMessage>();
        this.recordUpdatedEvent = new Subject<RecordUpdatedMessage>();
        this.recordUpdatedFilteredEvent = new Subject<RecordUpdatedMessage>();
        this.recordDeletedEvent = new Subject<RecordDeletedMessage>();
    }

    // endregion

    // region Methods

    async recordCreated(key: Entities, id: string): Promise<void> {
        let entity: Entity = this.entities.get(key);
        if (entity.linkedRequests != null) {
            entity.linkedRequests.forEach(request => this.removeRequestGroup(request));
        }
        let record = await this.getDataSingle(entity.getRequest, id);
        if (entity.filteredRequest) {
            this.removeRequestGroup(entity.listRequest);
            if (entity.selectRequest) {
                this.removeRequestGroup(entity.selectRequest);
            }
            this.removeRequestById(entity.getRequest, id);
            this.recordCreatedFilteredEvent.next({ key: key });
        }
        else {
            this.insertRecordList(entity.listRequest, record);
            if (entity.selectRequest) {
                this.insertRecordList(entity.selectRequest, record);
            }
            this.recordCreatedEvent.next({
                key: key,
                record: record
            });
        }
    }

    async recordUpdated(key: string, id: string): Promise<void> {
        let entity: Entity = this.entities.get(key);
        if (entity.linkedRequests != null) {
            entity.linkedRequests.forEach(request => this.removeRequestGroup(request));
        }
        this.removeRequestById(entity.getRequest, id);
        let record = await this.getDataSingle(entity.getRequest, id);
        if (entity.filteredRequest) {
            this.removeRequestGroup(entity.listRequest);
            if (entity.selectRequest) {
                this.removeRequestGroup(entity.selectRequest);
            }
            this.removeRequestById(entity.getRequest, id);
            this.recordUpdatedFilteredEvent.next({ key: key, id: id, record: record });
        }
        else {
            this.updateRecordList(entity.listRequest, id, record);
            if (entity.selectRequest) {
                this.updateRecordList(entity.selectRequest, id, record);
            }
            this.recordUpdatedEvent.next({
                key: key,
                id: id,
                record: record
            });
        }
    }

    recordDeleted(key: string, id: string): void {
        let entity: Entity = this.entities.get(key);
        if (entity.linkedRequests != null) {
            entity.linkedRequests.forEach(request => this.removeRequestGroup(request));
        }
        this.removeRecordList(entity.listRequest, id);
        if (entity.selectRequest != null) {
            this.removeRecordList(entity.selectRequest, id);
        }
        this.recordDeletedEvent.next({
            key: key,
            id: id
        });
    }

    private async getDataSingle(requestName: RequestNames, id: string): Promise<any> {
        return this.backend.DynamicGetSingle(requestName, { id: id }).toPromise();
    }

    isEntityCached(key: Entities): boolean {
        let entity: Entity = this.entities.get(key);
        return this.httpCache.dictionary.containsKey(entity.listRequest) || this.httpCache.dictionary.containsKey(entity.getRequest)
            || (entity.linkedRequests?.length > 0 && this.httpCache.dictionary.containsKey(entity.linkedRequests[0]));
    }

    // endregion

    // region Remove request

    removeListRequest(key: Entities): void {
        let entity: Entity = this.entities.get(key);
        this.removeRequestGroup(entity.listRequest);
    }

    removeRequests(key: Entities): void {
        let entity: Entity = this.entities.get(key);
        this.removeRequestGroup(entity.listRequest);
        this.removeRequestGroup(entity.getRequest);
    }

    removeRequestById(requestName: string, id: string): void {
        if (this.httpCache.dictionary.containsKey(requestName)) {
            let innerGroupDictionary = this.httpCache.dictionary.get(requestName);
            let entries = innerGroupDictionary.where(d => d.value.url.includes(id));
            entries.forEach(item => {
                innerGroupDictionary.remove(item.key);
            });
        }
    }

    removeRequestGroup(name: string): void {
        if (this.httpCache.dictionary.containsKey(name)) {
            this.httpCache.dictionary.remove(name);
        }
    }

    // endregion

    // region Insert

    private insertRecordList(requestName: string, record: any): void {
        if (this.httpCache.dictionary.containsKey(requestName)) {
            let innerGroupDictionary = this.httpCache.dictionary.get(requestName);
            let entry = innerGroupDictionary.where(d => !d.value.url.includes('skip') || d.value.url.includes('skip=0')).firstOrDefault(); // This will get the first request
            CacheManagerService.insertRecord(entry, record);
        }
    }

    private static insertRecord(entry: IKeyValue<string, HttpResponse<any>>, record: any): void {
        if (entry) {
            let response = entry.value;
            if (response.body instanceof Array) {
                let array = response.body as Array<any>;
                array.push(record);
            }
        }
    }

    // endregion

    // region Update

    private updateRecordList(requestName: string, id: string, record: any): void {
        if (this.httpCache.dictionary.containsKey(requestName)) {
            let innerGroupDictionary = this.httpCache.dictionary.get(requestName);
            CacheManagerService.updateRecord(innerGroupDictionary.asEnumerable(), id, record);
        }
    }

    private static updateRecord(entry: IEnumerable<IKeyValue<string, HttpResponse<any>>>, id: string, record: any): void {
        for (let item of entry.toArray()) {
            let body = item.value.body;
            // There can be multiple entries in the case of 'list' requests
            if (body instanceof Array) {
                let array = body as Array<any>;
                let index: number = array.findIndex(i => i.id == id);
                if (index != -1) {
                    array[index] = record;
                }
            }
        }
    }

    // endregion

    // region Update

    private removeRecordList(requestName: string, id: string): void {
        if (this.httpCache.dictionary.containsKey(requestName)) {
            let innerGroupDictionary = this.httpCache.dictionary.get(requestName);
            CacheManagerService.removeRecordFromList(innerGroupDictionary.asEnumerable(), id);
        }
    }

    private static removeRecordFromList(entry: IEnumerable<IKeyValue<string, HttpResponse<any>>>, id: string): void {
        for (let item of entry.toArray()) {
            if (item.value.body instanceof Array) {
                let array = item.value.body as Array<any>;
                let index: number = array.findIndex(i => i.id == id);
                if (index != -1) {
                    array.splice(index, 1); // Remove element at the specified index
                }
            }
        }
    }

    // endregion
}
