import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppointmentStatusComponent } from '@components/appointment-status/appointment-status.component';
import { AssignedUserTableComponent } from '@components/assigned-user-table/assigned-user-table.component';
import { DetailGridComponent } from '@components/detail-grid/detail-grid.component';
import { ConfirmDialogComponent } from '@components/dialog/confirm-dialog/confirm-dialog.component';
import { InputDialogComponent } from '@components/dialog/input-dialog/input-dialog.component';
import { FacilityTableComponent } from '@components/facility-table/facility-table.component';
import { FileUploaderComponent } from '@components/file-uploader/file-uploader.component';
import { ForgottenPasswordComponent } from '@components/forgotten-password/forgotten-password.component';
import { ListGridComponent } from '@components/list-grid/list-grid.component';
import { LoaderComponent } from '@components/loader/loader.component';
import { MenuItemComponent } from '@components/menu/menu-item/menu-item.component';
import { MenuComponent } from '@components/menu/menu/menu.component';
import { AppointmentDetailsComponent } from '@components/new-appointment/appointment-details/appointment-details.component';
import { AppointmentModalComponent } from '@components/new-appointment/appointment-modal.component';
import { NewAppointmentFleetComponent } from '@components/new-appointment/new-appointment-fleet/new-appointment-fleet.component';
import { NewAppointmentServiceLegacyComponent } from '@components/new-appointment/new-appointment-setvice-legacy/new-appointment-service-legacy.component';
import { NewAppointmentServiceComponent } from '@components/new-appointment/new-appointment-setvice/new-appointment-service.component';
import { NewCustomerModalComponent } from '@components/new-appointment/new-customer-modal/new-customer-modal.component';
import { NewVehicleModalComponent } from '@components/new-appointment/new-vehicle-modal/new-vehicle-modal.component';
import { ServiceCarTableComponent } from '@components/new-appointment/service-car-table/service-car-table.component';
import { DetailButtonsComponent } from '@components/page/detail-buttons/detail-buttons.component';
import { DetailContentComponent } from '@components/page/detail-content.component.ts/detail-content.component';
import { DetailHeaderComponent } from '@components/page/detail-header/detail-header.component';
import { ListContentComponent } from '@components/page/list-content.component.ts/list-content.component';
import { ListHeaderComponent } from '@components/page/list-header/list-header.component';
import { SlidingOptionsComponent } from '@components/page/sliding-options/sliding-options.component';
import { PictureUploaderComponent } from '@components/picture-uploader/picture-uploader.component';
import { CarSelectBoxComponent } from '@components/select-box/car-select-box/car-select-box.component';
import { CustomerSelectBoxComponent } from '@components/select-box/customer-select-box/customer-select-box.component';
import { FacilitySelectBoxComponent } from '@components/select-box/facility-select-box/facility-select-box.component';
import { FacilityTypeSelectBoxComponent } from '@components/select-box/facility-type-select-box/facility-type-select-box.component';
import { FleetCustomerSelectBoxComponent } from '@components/select-box/fleet-customer-select-box/fleet-customer-select-box.component';
import { MakeSelectBoxComponent } from '@components/select-box/make-select-box/make-select-box.component';
import { ModelSelectBoxComponent } from '@components/select-box/model-select-box/model-select-box.component';
import { SelectBoxGridComponent } from '@components/select-box/select-box-grid/select-box-grid.component';
import { SelectBoxListComponent } from '@components/select-box/select-box-list/select-box-list.component';
import { ServiceCarSelectBoxComponent } from '@components/select-box/service-car-select-box/service-car-select-box.component';
import { ServiceSelectBoxComponent } from '@components/select-box/service-select-box/service-select-box.component';
import { UserSelectBoxComponent } from '@components/select-box/user-select-box/user-select-box.component';
import { WorksheetSelectBoxComponent } from '@components/select-box/worksheet-select-box/worksheet-select-box.component';
import { AppointmentSelectListComponent } from '@components/select-list/appointment-select-list/appointment-select-list.component';
import { FacilitySelectListComponent } from '@components/select-list/facility-select-list/facility-select-list.component';
import { SelectListComponent } from '@components/select-list/select-list-component/select-list.component';
import { ServiceCarSelectListComponent } from '@components/select-list/service-car-select-list/service-car-select-list.component';
import { UserSelectListComponent } from '@components/select-list/user-select-list/user-select-list.component';
import { FaultDetailModalComponent } from '@components/worksheet-details-content/fault-detail-modal/fault-detail-modal.component';
import { WorksheetDetailsContentComponent } from '@components/worksheet-details-content/worksheet-details-content.component';
import { RepairPartGridComponent } from '@components/repair-part-grid/repair-part-grid.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import {
    DxButtonModule,
    DxCheckBoxModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxDropDownBoxModule,
    DxFileUploaderModule,
    DxListModule,
    DxNumberBoxModule,
    DxPopupModule,
    DxScrollViewModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxTreeViewModule,
    DxValidationSummaryModule,
    DxValidatorModule
} from 'devextreme-angular';

@NgModule({
    declarations: [
        ConfirmDialogComponent,
        InputDialogComponent,
        LoaderComponent,
        MenuComponent,
        MenuItemComponent,
        SlidingOptionsComponent,
        DetailButtonsComponent,
        DetailHeaderComponent,
        DetailContentComponent,
        ListHeaderComponent,
        ListContentComponent,
        ListGridComponent,
        DetailGridComponent,
        FileUploaderComponent,
        PictureUploaderComponent,
        SelectBoxGridComponent,
        SelectBoxListComponent,
        SelectListComponent,
        MakeSelectBoxComponent,
        ModelSelectBoxComponent,
        UserSelectBoxComponent,
        CarSelectBoxComponent,
        CustomerSelectBoxComponent,
        ServiceCarSelectBoxComponent,
        FleetCustomerSelectBoxComponent,
        UserSelectBoxComponent,
        WorksheetSelectBoxComponent,
        FacilityTypeSelectBoxComponent,
        FacilitySelectBoxComponent,
        ServiceSelectBoxComponent,
        AppointmentSelectListComponent,
        FacilitySelectListComponent,
        UserSelectListComponent,
        ServiceCarSelectListComponent,
        WorksheetDetailsContentComponent,
        ForgottenPasswordComponent,
        FaultDetailModalComponent,
        AppointmentStatusComponent,
        FacilityTableComponent,
        ServiceCarTableComponent,
        AssignedUserTableComponent,
        NewVehicleModalComponent,
        NewCustomerModalComponent,
        NewAppointmentFleetComponent,
        NewAppointmentServiceComponent,
        NewAppointmentServiceLegacyComponent,
        AppointmentDetailsComponent,
        AppointmentModalComponent,
        RepairPartGridComponent
    ],
    imports: [
        DxDataGridModule,
        DxTextBoxModule,
        DxNumberBoxModule,
        DxDateBoxModule,
        DxCheckBoxModule,
        DxDropDownBoxModule,
        DxTreeViewModule,
        DxSelectBoxModule,
        DxValidatorModule,
        DxListModule,
        DxScrollViewModule,
        DxButtonModule,
        DxPopupModule,
        DxFileUploaderModule,
        DxValidationSummaryModule,
        TranslateModule,
        IonicModule,
        CommonModule,
        FormsModule,
        RouterModule,
        SharedModule
    ],
    exports: [
        LoaderComponent,
        MenuComponent,
        MenuItemComponent,
        SlidingOptionsComponent,
        DetailButtonsComponent,
        DetailHeaderComponent,
        DetailContentComponent,
        ListHeaderComponent,
        ListContentComponent,
        ListGridComponent,
        DetailGridComponent,
        FileUploaderComponent,
        PictureUploaderComponent,
        SelectBoxGridComponent,
        SelectBoxListComponent,
        SelectListComponent,
        MakeSelectBoxComponent,
        ModelSelectBoxComponent,
        UserSelectBoxComponent,
        CarSelectBoxComponent,
        CustomerSelectBoxComponent,
        ServiceCarSelectBoxComponent,
        FleetCustomerSelectBoxComponent,
        UserSelectBoxComponent,
        WorksheetSelectBoxComponent,
        FacilityTypeSelectBoxComponent,
        FacilitySelectBoxComponent,
        ServiceSelectBoxComponent,
        AppointmentSelectListComponent,
        FacilitySelectListComponent,
        UserSelectListComponent,
        ServiceCarSelectListComponent,
        WorksheetDetailsContentComponent,
        ForgottenPasswordComponent,
        AppointmentStatusComponent,
        FacilityTableComponent,
        AssignedUserTableComponent,
        NewCustomerModalComponent,
        AppointmentModalComponent,
        RepairPartGridComponent
    ]
})
export class ComponentsModule {}
