
export enum RequestNames {
    Login = 'Login',
    RefreshAccessToken = 'RefreshAccessToken',
    ConfirmRegistration = 'ConfirmRegistration',
    SendConfirmRegistrationLink = 'SendConfirmRegistrationLink',
    ResetServicePassword = 'ResetServicePassword',

    GetListSetting = 'GetListSetting',
    UpdateListSetting = 'UpdateListSetting',

    GetMakes = 'GetMakes',

    GetServiceCarImage = 'GetServiceCarImage',
    GetCarImage = 'GetCarImage',

    GetUsers = 'GetUsers',
    SelectUsers = 'SelectUsers',
    GetUser = 'GetUser',
    AddUser = 'AddUser',
    UpdateUser = 'UpdateUser',
    DeleteUser = 'DeleteUser',
    GetCalendarEmployees = 'GetCalendarEmployees',
    ReorderServiceEmployees = 'ReorderServiceEmployees',

    GetCurrentUserData = 'GetCurrentUserData',
    RegisterUser = 'RegisterUser',
    GetMenuInfoCount = 'GetMenuInfoCount',
    GetFaultSuggestions = 'GetFaultSuggestions',
    GetServiceUserNotifications = 'GetServiceUserNotifications',
    SetServiceUserNotificationSeen = 'SetServiceUserNotificationSeen',

    GetUserSetting = 'GetUserSetting',
    UpdateUserSetting = 'UpdateUserSetting',

    GetFleetManagerSetting = 'GetFleetManagerSetting',
    UpdateFleetManagerSetting = 'UpdateFleetManagerSetting',

    GetUserProfile = 'GetUserProfile',
    UpdateUserProfile = 'UpdateUserProfile',
    UpdateUserPassword = 'UpdateUserPassword',

    GetFleetManagerProfile = 'GetFleetManagerProfile',
    UpdateFleetManagerProfile = 'UpdateFleetManagerProfile',

    SelectServices = 'SelectServices',
    GetService = 'GetService',
    GetAppService = 'GetAppService',
    UpdateService = 'UpdateService',
    DeleteService = 'DeleteService',

    GetServiceSetting = 'GetServiceSetting',
    UpdateServiceSetting = 'UpdateServiceSetting',

    GetHourlyFees = 'GetHourlyFees',
    SelectHourlyFees = 'SelectHourlyFees',
    GetHourlyFee = 'GetHourlyFee',
    AddHourlyFee = 'AddHourlyFee',
    UpdateHourlyFee = 'UpdateHourlyFee',
    DeleteHourlyFee = 'DeleteHourlyFee',

    GetRepairedMakes = 'GetRepairedMakes',
    UpdateRepairedMakes = 'UpdateRepairedMakes',

    CheckAccountSetUp = 'CheckAccountSetUp',
    CreateAccountLink = 'CreateAccountLink',
    CreateLoginLink = 'CreateLoginLink',
    DeleteAccount = 'DeleteAccount',

    GetAppointments = 'GetAppointments',
    SelectAppointments = 'SelectAppointments',
    GetAppointment = 'GetAppointment',
    AddAppointment = 'AddAppointment',
    UpdateAppointment = 'UpdateAppointment',
    DeleteAppointment = 'DeleteAppointment',
    GetCalendarAppointments = 'GetCalendarAppointments',

    BookAppointment = 'BookAppointment',
    BookServiceAppointment = 'BookServiceAppointment',
    GetAvailableAppointments = 'GetAvailableAppointments',
    UpdateServiceAppointment = 'UpdateServiceAppointment',

    GetFacilityTypes = 'GetFacilityTypes',
    SelectFacilityTypes = 'SelectFacilityTypes',
    GetFacilityType = 'GetFacilityType',
    AddFacilityType = 'AddFacilityType',
    UpdateFacilityType = 'UpdateFacilityType',
    DeleteFacilityType = 'DeleteFacilityType',
    ReorderFacilityTypes = 'ReorderFacilityTypes',

    GetFacilities = 'GetFacilities',
    SelectFacilities = 'SelectFacilities',
    GetFacility = 'GetFacility',
    AddFacility = 'AddFacility',
    UpdateFacility = 'UpdateFacility',
    DeleteFacility = 'DeleteFacility',
    GetCalendarFacilities = 'GetCalendarFacilities',
    ReorderFacilities = 'ReorderFacilities',

    GetWorksheets = 'GetWorksheets',
    SelectWorksheets = 'SelectWorksheets',
    GetWorksheet = 'GetWorksheet',
    AddWorksheet = 'AddWorksheet',
    UpdateWorksheet = 'UpdateWorksheet',
    DeleteWorksheet = 'DeleteWorksheet',
    RequestNewDate = 'RequestNewDate',
    HideNotification = 'HideNotification',
    CreateInvoice = 'CreateInvoice',
    DownloadInvoice = 'DownloadInvoice',
    DownloadSuzukiExport = 'DownloadSuzukiExport',
    GetManagerWorksheets = 'GetManagerWorksheets',
    GetMechanicWorksheets = 'GetMechanicWorksheets',
    GetDeliveryWorksheets = 'GetDeliveryWorksheets',
    GetFleetManagerWorksheets = 'GetFleetManagerWorksheets',
    AddComment = 'AddComment',
    GetServiceDeliveryData = 'GetServiceDeliveryData',
    AppointmentConfirmed = 'AppointmentConfirmed',
    NeedsPickUp = 'NeedsPickUp',
    PickingUpVehicle = 'PickingUpVehicle',
    VehiclePickedUp = 'VehiclePickedUp',
    DeliveringVehicle = 'DeliveringVehicle',
    VehicleArrived = 'VehicleArrived',
    UnderReview = 'UnderReview',
    WaitingWarehousePricing = 'WaitingWarehousePricing',
    WaitingManagerPricing = 'WaitingManagerPricing',
    WaitingConfirmation = 'WaitingConfirmation',
    WaitingRepairParts = 'WaitingRepairParts',
    WaitingRepair = 'WaitingRepair',
    UnderRepair = 'UnderRepair',
    WaitingCompletion = 'WaitingCompletion',
    WaitingPayment = 'WaitingPayment',
    NeedsReturning = 'NeedsReturning',
    ReturningVehicle = 'ReturningVehicle',
    VehicleReturned = 'VehicleReturned',
    Finished = 'Finished',
    ConfirmWorksheet = 'ConfirmWorksheet',
    PayWorksheet = 'PayWorksheet',
    CancelWorksheet = 'CancelWorksheet',

    GetCustomers = 'GetCustomers',
    SelectCustomers = 'SelectCustomers',
    GetCustomer = 'GetCustomer',
    AddCustomer = 'AddCustomer',
    UpdateCustomer = 'UpdateCustomer',
    DeleteCustomer = 'DeleteCustomer',

    GetServiceCars = 'GetServiceCars',
    SelectServiceCars = 'SelectServiceCars',
    GetServiceCar = 'GetServiceCar',
    AddServiceCar = 'AddServiceCar',
    UpdateServiceCar = 'UpdateServiceCar',
    DeleteServiceCar = 'DeleteServiceCar',

    GetCars = 'GetCars',
    SelectCars = 'SelectCars',
    GetCar = 'GetCar',
    AddCar = 'AddCar',
    UpdateCar = 'UpdateCar',
    DeleteCar = 'DeleteCar',

    GetFleetCustomers = 'GetFleetCustomers',
    SelectFleetCustomers = 'SelectFleetCustomers',
    GetFleetCustomer = 'GetFleetCustomer',

    GetServicePartners = 'GetServicePartners',
    GetServicePartner = 'GetServicePartner',
    AddServicePartner = 'AddServicePartner',
    DeleteServicePartner = 'DeleteServicePartner',
    AcceptServicePartner = 'AcceptServicePartner',
    GetCalendarServicePartners = 'GetCalendarServicePartners'
}

export enum HttpMethods {
    GET = 'GET',
    POST = 'POST',
    DELETE = 'DELETE',
    PUT = 'PUT',
    PATCH = 'PATCH'
}

export abstract class Requests {
    private static readonly ConcreteRequests = Requests.getConcreteRequests();

    private static getConcreteRequests() {
        let result = {};
        result[RequestNames.Login] = { method: HttpMethods.POST, url: 'login/servicelogin', transform: null };
        result[RequestNames.RefreshAccessToken] = { method: HttpMethods.POST, url: 'login/servicerefreshtoken', transform: null };
        result[RequestNames.ConfirmRegistration] = { method: HttpMethods.POST, url: 'login/confirmregistration', transform: null };
        result[RequestNames.SendConfirmRegistrationLink] = { method: HttpMethods.POST, url: 'login/sendconfirmregistrationlink', transform: null };
        result[RequestNames.ResetServicePassword] = { method: HttpMethods.POST, url: 'login/resetservicepassword', transform: null };

        result[RequestNames.GetListSetting] = { method: HttpMethods.GET, url: 'listsetting/{0}', transform: null };
        result[RequestNames.UpdateListSetting] = { method: HttpMethods.PATCH, url: 'listsetting/{0}', transform: null };

        result[RequestNames.GetMakes] = { method: HttpMethods.GET, url: 'makes', transform: null };

        result[RequestNames.GetServiceCarImage] = { method: HttpMethods.GET, url: 'carimages/servicecar/{0}', transform: null };
        result[RequestNames.GetCarImage] = { method: HttpMethods.GET, url: 'carimages/car/{0}', transform: null };

        result[RequestNames.GetUsers] = { method: HttpMethods.GET, url: 'serviceemployees', transform: null };
        result[RequestNames.SelectUsers] = { method: HttpMethods.GET, url: 'serviceemployees/select', transform: null };
        result[RequestNames.GetUser] = { method: HttpMethods.GET, url: 'serviceemployees/{0}', transform: null };
        result[RequestNames.AddUser] = { method: HttpMethods.POST, url: 'serviceemployees', transform: null };
        result[RequestNames.UpdateUser] = { method: HttpMethods.PUT, url: 'serviceemployees/{0}', transform: null };
        result[RequestNames.DeleteUser] = { method: HttpMethods.DELETE, url: 'serviceemployees/{0}', transform: null };
        result[RequestNames.GetCalendarEmployees] = { method: HttpMethods.GET, url: 'serviceemployees/calendar', transform: null };
        result[RequestNames.ReorderServiceEmployees] = { method: HttpMethods.POST, url: 'serviceemployees/reorder', transform: null };

        result[RequestNames.GetCurrentUserData] = { method: HttpMethods.GET, url: 'serviceusers/{0}/getuserdata', transform: null };
        result[RequestNames.RegisterUser] = { method: HttpMethods.POST, url: 'serviceusers/register', transform: null };
        result[RequestNames.GetMenuInfoCount] = { method: HttpMethods.GET, url: 'serviceusers/menuinfocount', transform: null };
        result[RequestNames.GetFaultSuggestions] = { method: HttpMethods.GET, url: 'serviceusers/faultsuggestions', transform: null };
        result[RequestNames.GetServiceUserNotifications] = { method: HttpMethods.GET, url: 'serviceusers/notifications', transform: null };
        result[RequestNames.SetServiceUserNotificationSeen] = { method: HttpMethods.POST, url: 'serviceusers/{0}/notificationseen', transform: null };

        result[RequestNames.GetUserSetting] = { method: HttpMethods.GET, url: 'serviceusersetting', transform: null };
        result[RequestNames.UpdateUserSetting] = { method: HttpMethods.PUT, url: 'serviceusersetting', transform: null };

        result[RequestNames.GetFleetManagerSetting] = { method: HttpMethods.GET, url: 'fleetmanagersetting', transform: null };
        result[RequestNames.UpdateFleetManagerSetting] = { method: HttpMethods.PUT, url: 'fleetmanagersetting', transform: null };

        result[RequestNames.GetFleetManagerProfile] = { method: HttpMethods.GET, url: 'serviceuserprofile/fleetmanager', transform: null };
        result[RequestNames.UpdateFleetManagerProfile] = { method: HttpMethods.PUT, url: 'serviceuserprofile/fleetmanager', transform: null };

        result[RequestNames.GetUserProfile] = { method: HttpMethods.GET, url: 'serviceuserprofile', transform: null };
        result[RequestNames.UpdateUserProfile] = { method: HttpMethods.PUT, url: 'serviceuserprofile', transform: null };
        result[RequestNames.UpdateUserPassword] = { method: HttpMethods.PUT, url: 'serviceuserprofile/updatepassword', transform: null };

        result[RequestNames.SelectServices] = { method: HttpMethods.GET, url: 'services/select', transform: null };
        result[RequestNames.GetService] = { method: HttpMethods.GET, url: 'services/{0}', transform: null };
        result[RequestNames.GetAppService] = { method: HttpMethods.GET, url: 'services/{0}/app', transform: null };
        result[RequestNames.UpdateService] = { method: HttpMethods.PUT, url: 'services/{0}', transform: null };
        result[RequestNames.DeleteService] = { method: HttpMethods.DELETE, url: 'services', transform: null };

        result[RequestNames.GetServiceSetting] = { method: HttpMethods.GET, url: 'servicesetting', transform: null };
        result[RequestNames.UpdateServiceSetting] = { method: HttpMethods.PUT, url: 'servicesetting', transform: null };

        result[RequestNames.GetHourlyFees] = { method: HttpMethods.GET, url: 'hourlyfees', transform: null };
        result[RequestNames.SelectHourlyFees] = { method: HttpMethods.GET, url: 'hourlyfees/select', transform: null };
        result[RequestNames.GetHourlyFee] = { method: HttpMethods.GET, url: 'hourlyfees/{0}', transform: null };
        result[RequestNames.AddHourlyFee] = { method: HttpMethods.POST, url: 'hourlyfees', transform: null };
        result[RequestNames.UpdateHourlyFee] = { method: HttpMethods.PUT, url: 'hourlyfees/{0}', transform: null };
        result[RequestNames.DeleteHourlyFee] = { method: HttpMethods.DELETE, url: 'hourlyfees/{0}', transform: null };

        result[RequestNames.GetRepairedMakes] = { method: HttpMethods.GET, url: 'services/{0}/repairedmakes', transform: null };
        result[RequestNames.UpdateRepairedMakes] = { method: HttpMethods.PUT, url: 'services/{0}/repairedmakes', transform: null };

        result[RequestNames.CheckAccountSetUp] = { method: HttpMethods.POST, url: 'accounts/check', transform: null };
        result[RequestNames.CreateAccountLink] = { method: HttpMethods.POST, url: 'accounts/link', transform: null };
        result[RequestNames.CreateLoginLink] = { method: HttpMethods.POST, url: 'accounts/loginlink', transform: null };
        result[RequestNames.DeleteAccount] = { method: HttpMethods.DELETE, url: 'accounts', transform: null };

        result[RequestNames.GetAppointments] = { method: HttpMethods.GET, url: 'appointments', transform: null };
        result[RequestNames.SelectAppointments] = { method: HttpMethods.GET, url: 'appointments/select', transform: null };
        result[RequestNames.GetAppointment] = { method: HttpMethods.GET, url: 'appointments/{0}', transform: null };
        result[RequestNames.AddAppointment] = { method: HttpMethods.POST, url: 'appointments', transform: null };
        result[RequestNames.UpdateAppointment] = { method: HttpMethods.PUT, url: 'appointments/{0}', transform: null };
        result[RequestNames.DeleteAppointment] = { method: HttpMethods.DELETE, url: 'appointments/{0}', transform: null };
        result[RequestNames.GetCalendarAppointments] = { method: HttpMethods.GET, url: 'appointments/calendar', transform: null };

        result[RequestNames.BookAppointment] = { method: HttpMethods.POST, url: 'appointments/book', transform: null };
        result[RequestNames.BookServiceAppointment] = { method: HttpMethods.POST, url: 'appointments/bookservice', transform: null };
        result[RequestNames.GetAvailableAppointments] = { method: HttpMethods.POST, url: 'appointments/available', transform: null };
        result[RequestNames.UpdateServiceAppointment] = { method: HttpMethods.POST, url: 'appointments/update', transform: null };

        result[RequestNames.GetFacilityTypes] = { method: HttpMethods.GET, url: 'facilitytypes', transform: null };
        result[RequestNames.SelectFacilityTypes] = { method: HttpMethods.GET, url: 'facilitytypes/select', transform: null };
        result[RequestNames.GetFacilityType] = { method: HttpMethods.GET, url: 'facilitytypes/{0}', transform: null };
        result[RequestNames.AddFacilityType] = { method: HttpMethods.POST, url: 'facilitytypes', transform: null };
        result[RequestNames.UpdateFacilityType] = { method: HttpMethods.PUT, url: 'facilitytypes/{0}', transform: null };
        result[RequestNames.DeleteFacilityType] = { method: HttpMethods.DELETE, url: 'facilitytypes/{0}', transform: null };
        result[RequestNames.ReorderFacilityTypes] = { method: HttpMethods.POST, url: 'facilitytypes/reorder', transform: null };

        result[RequestNames.GetFacilities] = { method: HttpMethods.GET, url: 'facilities', transform: null };
        result[RequestNames.SelectFacilities] = { method: HttpMethods.GET, url: 'facilities/select', transform: null };
        result[RequestNames.GetFacility] = { method: HttpMethods.GET, url: 'facilities/{0}', transform: null };
        result[RequestNames.AddFacility] = { method: HttpMethods.POST, url: 'facilities', transform: null };
        result[RequestNames.UpdateFacility] = { method: HttpMethods.PUT, url: 'facilities/{0}', transform: null };
        result[RequestNames.DeleteFacility] = { method: HttpMethods.DELETE, url: 'facilities/{0}', transform: null };
        result[RequestNames.GetCalendarFacilities] = { method: HttpMethods.GET, url: 'facilities/calendar', transform: null };
        result[RequestNames.ReorderFacilities] = { method: HttpMethods.POST, url: 'facilities/reorder', transform: null };

        result[RequestNames.GetWorksheets] = { method: HttpMethods.GET, url: 'worksheets', transform: null };
        result[RequestNames.SelectWorksheets] = { method: HttpMethods.GET, url: 'worksheets/select', transform: null };
        result[RequestNames.GetWorksheet] = { method: HttpMethods.GET, url: 'worksheets/{0}', transform: null };
        result[RequestNames.AddWorksheet] = { method: HttpMethods.POST, url: 'worksheets', transform: null };
        result[RequestNames.UpdateWorksheet] = { method: HttpMethods.PUT, url: 'worksheets/{0}', transform: null };
        result[RequestNames.DeleteWorksheet] = { method: HttpMethods.DELETE, url: 'worksheets/{0}', transform: null };
        result[RequestNames.RequestNewDate] = { method: HttpMethods.POST, url: 'worksheets/{0}/requestnewdate', transform: null };
        result[RequestNames.HideNotification] = { method: HttpMethods.POST, url: 'worksheets/{0}/hidenotification', transform: null };
        result[RequestNames.CreateInvoice] = { method: HttpMethods.POST, url: 'worksheets/{0}/invoice', transform: null };
        result[RequestNames.DownloadInvoice] = { method: HttpMethods.POST, url: 'worksheets/{0}/download', transform: null };
        result[RequestNames.DownloadSuzukiExport] = { method: HttpMethods.POST, url: 'worksheets/suzukiexport', transform: null };
        result[RequestNames.GetManagerWorksheets] = { method: HttpMethods.GET, url: 'worksheets/manager', transform: null };
        result[RequestNames.GetMechanicWorksheets] = { method: HttpMethods.GET, url: 'worksheets/mechanic', transform: null };
        result[RequestNames.GetDeliveryWorksheets] = { method: HttpMethods.GET, url: 'worksheets/delivery', transform: null };
        result[RequestNames.GetFleetManagerWorksheets] = { method: HttpMethods.GET, url: 'worksheets/fleetmanager', transform: null };
        result[RequestNames.AddComment] = { method: HttpMethods.POST, url: 'worksheets/{0}/comment', transform: null };
        result[RequestNames.GetServiceDeliveryData] = { method: HttpMethods.GET, url: 'worksheets/{0}/servicedelivery', transform: null };
        result[RequestNames.AppointmentConfirmed] = { method: HttpMethods.POST, url: 'worksheets/{0}/appointmentconfirmed', transform: null };
        result[RequestNames.NeedsPickUp] = { method: HttpMethods.POST, url: 'worksheets/{0}/needspickup', transform: null };
        result[RequestNames.PickingUpVehicle] = { method: HttpMethods.POST, url: 'worksheets/{0}/pickingupvehicle', transform: null };
        result[RequestNames.VehiclePickedUp] = { method: HttpMethods.POST, url: 'worksheets/{0}/vehiclepickedup', transform: null };
        result[RequestNames.DeliveringVehicle] = { method: HttpMethods.POST, url: 'worksheets/{0}/deliveringvehicle', transform: null };
        result[RequestNames.VehicleArrived] = { method: HttpMethods.POST, url: 'worksheets/{0}/vehiclearrived', transform: null };
        result[RequestNames.UnderReview] = { method: HttpMethods.POST, url: 'worksheets/{0}/underreview', transform: null };
        result[RequestNames.WaitingWarehousePricing] = { method: HttpMethods.POST, url: 'worksheets/{0}/waitingwarehousepricing', transform: null };
        result[RequestNames.WaitingManagerPricing] = { method: HttpMethods.POST, url: 'worksheets/{0}/waitingmanagerpricing', transform: null };
        result[RequestNames.WaitingConfirmation] = { method: HttpMethods.POST, url: 'worksheets/{0}/waitingconfirmation', transform: null };
        result[RequestNames.WaitingRepairParts] = { method: HttpMethods.POST, url: 'worksheets/{0}/waitingrepairparts', transform: null };
        result[RequestNames.WaitingRepair] = { method: HttpMethods.POST, url: 'worksheets/{0}/waitingrepair', transform: null };
        result[RequestNames.UnderRepair] = { method: HttpMethods.POST, url: 'worksheets/{0}/underrepair', transform: null };
        result[RequestNames.WaitingCompletion] = { method: HttpMethods.POST, url: 'worksheets/{0}/waitingcompletion', transform: null };
        result[RequestNames.WaitingPayment] = { method: HttpMethods.POST, url: 'worksheets/{0}/waitingpayment', transform: null };
        result[RequestNames.NeedsReturning] = { method: HttpMethods.POST, url: 'worksheets/{0}/needsreturning', transform: null };
        result[RequestNames.ReturningVehicle] = { method: HttpMethods.POST, url: 'worksheets/{0}/returningvehicle', transform: null };
        result[RequestNames.VehicleReturned] = { method: HttpMethods.POST, url: 'worksheets/{0}/vehiclereturned', transform: null };
        result[RequestNames.Finished] = { method: HttpMethods.POST, url: 'worksheets/{0}/finished', transform: null };
        result[RequestNames.ConfirmWorksheet] = { method: HttpMethods.POST, url: 'worksheets/{0}/confirm', transform: null };
        result[RequestNames.PayWorksheet] = { method: HttpMethods.POST, url: 'worksheets/{0}/pay', transform: null };
        result[RequestNames.CancelWorksheet] = { method: HttpMethods.POST, url: 'worksheets/{0}/cancel', transform: null };

        result[RequestNames.GetCustomers] = { method: HttpMethods.GET, url: 'customers', transform: null };
        result[RequestNames.SelectCustomers] = { method: HttpMethods.GET, url: 'customers/select', transform: null };
        result[RequestNames.GetCustomer] = { method: HttpMethods.GET, url: 'customers/{0}', transform: null };
        result[RequestNames.AddCustomer] = { method: HttpMethods.POST, url: 'customers', transform: null };
        result[RequestNames.UpdateCustomer] = { method: HttpMethods.PUT, url: 'customers/{0}', transform: null };
        result[RequestNames.DeleteCustomer] = { method: HttpMethods.DELETE, url: 'customers/{0}', transform: null };

        result[RequestNames.GetCars] = { method: HttpMethods.GET, url: 'cars', transform: null };
        result[RequestNames.SelectCars] = { method: HttpMethods.GET, url: 'cars', transform: null };
        result[RequestNames.GetCar] = { method: HttpMethods.GET, url: 'cars/{0}/fleet', transform: null };
        result[RequestNames.AddCar] = { method: HttpMethods.POST, url: 'cars/fleet', transform: null };
        result[RequestNames.UpdateCar] = { method: HttpMethods.PUT, url: 'cars/{0}', transform: null };
        result[RequestNames.DeleteCar] = { method: HttpMethods.DELETE, url: 'cars/{0}', transform: null };

        result[RequestNames.GetServiceCars] = { method: HttpMethods.GET, url: 'servicecars', transform: null };
        result[RequestNames.SelectServiceCars] = { method: HttpMethods.GET, url: 'servicecars/select', transform: null };
        result[RequestNames.GetServiceCar] = { method: HttpMethods.GET, url: 'servicecars/{0}', transform: null };
        result[RequestNames.AddServiceCar] = { method: HttpMethods.POST, url: 'servicecars', transform: null };
        result[RequestNames.UpdateServiceCar] = { method: HttpMethods.PUT, url: 'servicecars/{0}', transform: null };
        result[RequestNames.DeleteServiceCar] = { method: HttpMethods.DELETE, url: 'servicecars/{0}', transform: null };

        result[RequestNames.GetFleetCustomers] = { method: HttpMethods.GET, url: 'fleetcustomers', transform: null };
        result[RequestNames.SelectFleetCustomers] = { method: HttpMethods.GET, url: 'fleetcustomers/select', transform: null };
        result[RequestNames.GetFleetCustomer] = { method: HttpMethods.GET, url: 'fleetcustomers/{0}', transform: null };

        result[RequestNames.GetServicePartners] = { method: HttpMethods.GET, url: 'servicepartners', transform: null };
        result[RequestNames.GetServicePartner] = { method: HttpMethods.GET, url: 'servicepartners/{0}', transform: null };
        result[RequestNames.AddServicePartner] = { method: HttpMethods.POST, url: 'servicepartners', transform: null };
        result[RequestNames.DeleteServicePartner] = { method: HttpMethods.DELETE, url: 'servicepartners/{0}', transform: null };
        result[RequestNames.AcceptServicePartner] = { method: HttpMethods.POST, url: 'servicepartners/{0}/accept', transform: null };
        result[RequestNames.GetCalendarServicePartners] = { method: HttpMethods.GET, url: 'servicepartners/calendar', transform: null };

        return result;
    }

    static get(requestName: string): RequestData {
        let requestExists = Requests.ConcreteRequests.hasOwnProperty(requestName);
        if (!requestExists) {
            throw new Error(`Could not find HTTP Method mapping for request: ${requestName}`);
        }

        return Requests.ConcreteRequests[requestName];
    }
}

export interface RequestData {
    method: string;
    url: string;
    transform: (outputModel: object) => object;
}
