import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LanguageService } from '@services/language/language.service';
import { Observable } from 'rxjs';
import { InterceptorMethods } from './interceptor-methods';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

    constructor(
        private language: LanguageService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!InterceptorMethods.shouldIntercept(request)) {
            return next.handle(request);
        }

        return next.handle(request.clone({ ...request, headers: request.headers.set('Accept-Language', this.language.language)}));
    }
}
