export interface ShowConfirmDialogArgs {
    title: string;
    message: string;
    buttonType: ButtonType;
    confirmText: string | null;
}

export enum ButtonType {
    Confirm = 0,
    YesNo = 1,
    Ok = 3
}

export interface ShowInputDialogArgs {
    title: string;
    message: string;
}
