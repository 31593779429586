import { HttpRequest } from '@angular/common/http';
import { RequestNames } from '../requests';
import { InterceptorConstants as IC } from './interceptor-constants';

export abstract class InterceptorMethods {

    static shouldIntercept(request: HttpRequest<any>): boolean {
        return !!request.headers.get(IC.UseInterceptors);
    }

    static getRequestName(request: HttpRequest<any>): RequestNames | string {
        let requestName = request.headers.get(IC.RequestName) || '';
        let enumValue = RequestNames[requestName];
        return enumValue || requestName;
    }

    static shouldNotCache(request: HttpRequest<any>): boolean {
        return !!request.headers.get(IC.NoCache);
    }
}
