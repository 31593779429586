import { RequestNames } from '../backend/requests';

export interface Entity {
    listRequest: RequestNames;
    getRequest: RequestNames;
    selectRequest: RequestNames;
    filteredRequest: boolean;
    linkedRequests?: Array<RequestNames>;
}

export interface RecordMessage {
    key: string;
}

export interface RecordCreatedMessage {
    key: string;
    record: any;
}

export interface RecordUpdatedMessage {
    key: string;
    id: string;
    record: any;
}

export interface RecordDeletedMessage {
    key: string;
    id: string;
}

// TODO add entities
export enum Entities {
    UserManagement = 'users/management',
    UserSetting = 'users/setting',
    UserProfile = 'users/profile',
    FleetProfile = 'users/fleetprofile',
    Registration = 'registration',
    Appointments = 'appointments',
    Calendar = 'calendar',
    FacilityTypes = 'facilitytypes',
    Facilities = 'facilities',
    Service = 'settings/service',
    ServiceSetting = 'settings/servicesettings',
    FleetSetting = 'settings/fleetsettings',
    Account = 'settings/account',
    RepairedMakes = 'settings/repairedmakes',
    ServicePartners = 'settings/servicepartners',
    AppointmentWorksheets = 'appointmentworksheets',
    Worksheets = 'worksheets',
    FinishedWorksheets = 'finishedworksheets',
    Customers = 'customers',
    ServiceCars = 'servicecars',
    Cars = 'cars',
    FleetCustomers = 'fleetcustomers'
}
