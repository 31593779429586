import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpCache } from '../http-cache';
import { HttpMethods } from '../requests';
import { InterceptorConstants as IC } from './interceptor-constants';
import { InterceptorMethods } from './interceptor-methods';

//
// Implementation based on:
// https://angular.io/guide/http#caching
//

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

    constructor(private cache: HttpCache) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!InterceptorMethods.shouldIntercept(request) || request.method !== HttpMethods.GET) {
            return next.handle(request);
        }

        let requestName = request.headers.get(IC.RequestName);

        if (InterceptorMethods.shouldNotCache(request)) {
            this.cache.removeRequestGroup(requestName);
        }

        let cachedResponse = this.cache.get(requestName, request);
        if (cachedResponse) {
            return of(cachedResponse); // A cached response exists. Serve it instead of forwarding the request to the next handler.
        }
        else {
            return next.handle(request)
                .pipe(
                    tap(event => {
                        // No cached response exists. Go to the network, and cache the response when it arrives.
                        this.cacheResponse(event, request, requestName);
                    })
                );
        }
    }

    private cacheResponse(event, request: HttpRequest<any>, requestName: string) {
        if (!(event instanceof HttpResponse)) {
            return;
        }

        this.cache.put(requestName, request, event);
    }
}
