import { HttpClientJsonpModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { GoogleMapsModule } from "@angular/google-maps";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import {
    DxAutocompleteModule,
    DxButtonModule,
    DxCheckBoxModule,
    DxColorBoxModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxDropDownBoxModule,
    DxFileUploaderModule,
    DxFormModule,
    DxListModule,
    DxNumberBoxModule,
    DxPopupModule,
    DxRadioGroupModule,
    DxSchedulerModule,
    DxScrollViewModule,
    DxSelectBoxModule,
    DxSortableModule,
    DxTabsModule,
    DxTagBoxModule,
    DxTextAreaModule,
    DxTextBoxModule,
    DxToolbarModule,
    DxValidationGroupModule,
    DxValidatorModule,
} from "devextreme-angular";
import { DxiValidationRuleModule } from "devextreme-angular/ui/nested";
import { PointDecimalPipe } from "./point-decimal-pipe";
import { DecimalPipe } from "@angular/common";

@NgModule({
    declarations: [PointDecimalPipe],
    providers: [DecimalPipe],
    exports: [
        PointDecimalPipe,

        TranslateModule,

        IonicModule,

        GoogleMapsModule,
        HttpClientJsonpModule,

        DxDataGridModule,
        DxListModule,
        DxButtonModule,
        DxTextBoxModule,
        DxTextAreaModule,
        DxNumberBoxModule,
        DxDateBoxModule,
        DxCheckBoxModule,
        DxAutocompleteModule,
        DxFileUploaderModule,
        DxDropDownBoxModule,
        DxScrollViewModule,
        DxSelectBoxModule,
        DxTagBoxModule,
        DxColorBoxModule,
        DxValidatorModule,
        DxiValidationRuleModule,
        DxPopupModule,
        DxToolbarModule,
        DxFormModule,
        DxValidationGroupModule,
        DxRadioGroupModule,
        DxSortableModule,
        DxSchedulerModule,
        DxTabsModule,
    ],
})
export class SharedModule {}

