import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { NavController } from '@ionic/angular';
import { LoadingService } from '@services/loading/loading.service';
import { Utils } from '@shared/utils';
import { UserService } from '../user/user.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanLoad {

    // region Initialization

    constructor(
        private navCtrl: NavController,
        private user: UserService,
        private loading: LoadingService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.checkAuthenticated(state.url);
    }

    canLoad(route: Route, segments: Array<UrlSegment>): Promise<boolean> {
        return this.checkAuthenticated(Utils.getUrlFromSegments(segments));
    }

    private checkAuthenticated(url: string): Promise<boolean> {
        return new Promise(async resolve => {
            let authToken: string = this.user.getAuthToken();
            if (authToken != null) {
                if (!this.user.isLoggedIn) {
                    // Refresh expired access token
                    if (this.user.isAuthTokenExpired(authToken)) {
                        try {
                            await this.user.refreshAccessToken();
                        }
                        catch (e) {
                            this.user.logOut();
                            resolve(false);
                            return;
                        }
                    }
                    else {
                        this.user.extractUserDataFromAuthToken(authToken);
                    }

                    // Call methods after login, then redirect to main
                    this.user.afterLogin()
                        .then(() => {
                            this.loading.showLoadingIndicator = false;
                            resolve(true);
                        })
                        .catch(() => {
                            this.user.logOut();
                            resolve(false);
                        });
                }
                else {
                    resolve(true);
                }
            }
            else {
                // Not logged in so redirect to login page
                this.navCtrl.navigateRoot(['/login'], { queryParams: { returnUrl: url } });
                resolve(false);
            }
        });
    }

    // endregion
}
