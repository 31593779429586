import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { DevicePlatform } from '@services/backend/models/generated-models';

@Injectable({ providedIn: 'root' })
export class PlatformService {

    // region is(platform)

    private _isAndroid: boolean = undefined;
    get isAndroid(): boolean {
        if (this._isAndroid === undefined) {
            this._isAndroid = this.platform.is('android');
        }
        return this._isAndroid;
    }

    private _isDesktop: boolean = undefined;
    get isDesktop(): boolean {
        if (this._isDesktop === undefined) {
            this._isDesktop = this.platform.is('desktop');
        }
        return this._isDesktop;
    }

    private _isIos: boolean = undefined;
    get isIos(): boolean {
        if (this._isIos === undefined) {
            this._isIos = this.platform.is('ios') || this.platform.is('ipad') || this.platform.is('iphone');
        }
        return this._isIos;
    }

    private _isIpad: boolean = undefined;
    get isIpad(): boolean {
        if (this._isIpad === undefined) {
            this._isIpad = this.platform.is('ipad');
        }
        return this._isIpad;
    }

    private _isIphone: boolean = undefined;
    get isIphone(): boolean {
        if (this._isIphone === undefined) {
            this._isIphone = this.platform.is('iphone');
        }
        return this._isIphone;
    }

    private _isMobile: boolean = undefined;
    get isMobile(): boolean {
        if (this._isMobile === undefined) {
            this._isMobile = this.platform.is('mobile') || this.platform.is('iphone') || this.platform.is('android');
            this._isMobile = this._isMobile && !this.isTablet;
        }
        return this._isMobile;
    }

    private _isMobileWeb: boolean = undefined;
    get isMobileWeb(): boolean {
        if (this._isMobileWeb === undefined) {
            this._isMobileWeb = this.platform.is('mobileweb');
        }
        return this._isMobileWeb;
    }

    private _isTablet: boolean = undefined;
    get isTablet(): boolean {
        if (this._isTablet === undefined) {
            this._isTablet = this.platform.is('tablet') || this.platform.is('ipad');
        }
        return this._isTablet;
    }

    private _isWeb: boolean = undefined;
    get isWeb(): boolean {
        if (this._isWeb === undefined) {
            this._isWeb = this.platform.is('mobileweb') || this.platform.is('pwa') || this.platform.is('desktop');
        }
        return this._isWeb;
    }

    // endregion

    // region Initialization

    constructor(
        private readonly platform: Platform,
    ) {
        this.logPlatformReport();
    }

    // endregion

    // region Methods

    getDevicePlatform(): DevicePlatform {
        if (this.isIos) {
            return DevicePlatform.Ios;
        }
        if (this.isAndroid) {
            return DevicePlatform.Android;
        }
        if (this.isWeb) {
            return DevicePlatform.Web;
        }
        return null;
    }

    getPlatformColCount(): number {
        return this.isMobile || this.isMobileWeb ? 1 : 2;
    }

    getPlatformDesignCode(): string {
        if (this.isIos) {
            return 'ios';
        }
        if (this.isAndroid) {
            return 'md';
        }
        return 'core';
    }

    logPlatformReport(): void {
        let isAndroid = this.isAndroid;
        let isDesktop = this.isDesktop;
        let isIos = this.isIos;
        let isIpad = this.isIpad;
        let isIphone = this.isIphone;
        let isMobile = this.isMobile;
        let isMobileWeb = this.isMobileWeb;
        let isTablet = this.isTablet;
        let isWeb = this.isWeb;
        let availablePlatforms = this.platform.platforms();

        console.log(`((==)) isAndroid:   ${isAndroid}`);
        console.log(`((==)) isDesktop:   ${isDesktop}`);
        console.log(`((==)) isIos:       ${isIos}`);
        console.log(`((==)) isIpad:      ${isIpad}`);
        console.log(`((==)) isIphone:    ${isIphone}`);
        console.log(`((==)) isMobile:    ${isMobile}`);
        console.log(`((==)) isMobileWeb: ${isMobileWeb}`);
        console.log(`((==)) isTablet:    ${isTablet}`);
        console.log(`((==)) isWeb:       ${isWeb}`);
        console.log(`((==)) availablePlatforms: ${availablePlatforms}`);
    }

    // endregion
}
