import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    FacilityTypeCategory,
    RegisterServiceType,
    RegisterUserType,
    ServiceUserStatus,
    ServiceUserType, VehicleFuelType,
    WorksheetStatus
} from '@services/backend/models/generated-models';
import { StorageService } from '@services/storage/storage.service';
import { Constants } from '@shared/constants';
import { SelectOption } from '@shared/select-option';
import { TranslateConfig } from '@shared/translate-config';
import { loadMessages, locale } from 'devextreme/localization';
import { IList, List } from 'linq-collections';
import { ReplaySubject } from 'rxjs';
import enMessages from '../../../assets/locale/ndru-devextreme-en.json';
import huMessages from '../../../assets/locale/ndru-devextreme-hu.json';
import { PlatformService } from '../platform/platform.service';
import { FacilityDurationUnit } from '@services/backend/models/custom-models';

@Injectable({ providedIn: 'root' })
export class LanguageService {

    // region Fields

    readonly languageInitialized: ReplaySubject<boolean>;

    registerUserTypes: IList<SelectOption>;
    registerServiceTypes: IList<SelectOption>;

    serviceUserTypes: IList<SelectOption>;
    serviceUserStatus: IList<SelectOption>;

    worksheetStatus: IList<SelectOption>;

    facilityTypeCategories: IList<SelectOption>;

    vehicleFuelTypes: IList<SelectOption>;

    facilityDurationUnits: IList<SelectOption>;

    language: string;

    supportedLanguages: Array<SelectOption>;

    // endregion

    // region Initialization

    constructor(
        private storage: StorageService,
        private translate: TranslateService,
        private platform: PlatformService
    ) {
        this.languageInitialized = new ReplaySubject<boolean>(1);

        this.language = null;

        this.supportedLanguages = [{ caption: 'HU', value: 'hu-HU' }, { caption: 'EN', value: 'en-US' }];
    }

    /**
     * Runs at application start
     */
    async init() {
        // Get language from storage first
        let language: string = this.storage.getLanguage();
        if (language == null) {
            // Language should default to hu
            language = this.translate.getBrowserCultureLang();
            
            if (language != 'hu-HU' && language != 'en-US' && language != 'en-GB') {
                language = Constants.defaultLanguage;
            }
        }

        if (language == 'en-GB') {
            language = 'en-US';
        }

        // Load DevExtreme localization
        if (language == 'hu-HU') {
            loadMessages(huMessages);
        }
        else {
            loadMessages(enMessages);
        }

        await this.setLanguage(language);
    }

    async setLanguage(language: string) {
        if (this.language != language) {
            this.language = language;

            this.storage.setLanguage(this.language);

            await TranslateConfig.init(this.translate, this.language.substr(0, 2));

            // Set DevExtreme locale
            locale(this.language);

            this.initSelectOptions();

            this.languageInitialized.next(true);
        }
    }

    initSelectOptions(deliveryEnabled: boolean = false) {
        // TODO add additional entities here
        // TODO also filter by permissions
        this.facilityDurationUnits = new List<SelectOption>([
            { value: FacilityDurationUnit.Days, caption: this.translate.instant('facility_duration_unit_day') },
            { value: FacilityDurationUnit.Hours, caption: this.translate.instant('facility_duration_unit_hour') },
            { value: FacilityDurationUnit.Minutes, caption: this.translate.instant('facility_duration_unit_minute') }
        ]);

        this.registerUserTypes = new List<SelectOption>([
            { value: RegisterUserType.ServiceManager, caption: this.translate.instant('register_user_type_service_manager') },
            { value: RegisterUserType.FleetManager, caption: this.translate.instant('register_user_type_fleet_manager') }
        ]);
        this.registerServiceTypes = new List<SelectOption>([
            { value: RegisterServiceType.Service, caption: this.translate.instant('register_service_type_service') },
            { value: RegisterServiceType.CarWash, caption: this.translate.instant('register_service_type_car_wash') },
            { value: RegisterServiceType.TireShop, caption: this.translate.instant('register_service_type_tireshop') },
            { value: RegisterServiceType.MOTTestingStation, caption: this.translate.instant('register_service_type_MOT_test_station') }
        ]);
        this.serviceUserTypes = new List<SelectOption>([
            { value: ServiceUserType.Manager, caption: this.translate.instant('user_management_type_manager') },
            { value: ServiceUserType.Mechanic, caption: this.translate.instant('user_management_type_mechanic') },
            { value: ServiceUserType.Warehouse, caption: this.translate.instant('user_management_type_warehouse') },
            { value: ServiceUserType.Delivery, caption: this.translate.instant('user_management_type_delivery') }
        ]);
        this.serviceUserStatus = new List<SelectOption>([
            { value: ServiceUserStatus.Active, caption: this.translate.instant('service_user_status_active') },
            { value: ServiceUserStatus.Inactive, caption: this.translate.instant('service_user_status_inactive') }
        ]);
        this.worksheetStatus = new List<SelectOption>([
            { value: WorksheetStatus.AppointmentReserved, caption: this.translate.instant('worksheet_status_appointment_reserved'), visible: true },
            { value: WorksheetStatus.AppointmentConfirmed, caption: this.translate.instant('worksheet_status_appointment_confirmed'), visible: true },
            { value: WorksheetStatus.NeedsPickUp, caption: this.translate.instant('worksheet_status_needs_pick_up'), visible: deliveryEnabled },
            { value: WorksheetStatus.PickingUpVehicle, caption: this.translate.instant('worksheet_status_picking_up_vehicle'), visible: deliveryEnabled },
            { value: WorksheetStatus.VehiclePickedUp, caption: this.translate.instant('worksheet_status_vehicle_picked_up'), visible: deliveryEnabled },
            { value: WorksheetStatus.DeliveringVehicle, caption: this.translate.instant('worksheet_status_delivering_vehicle'), visible: deliveryEnabled },
            { value: WorksheetStatus.VehicleArrived, caption: this.translate.instant('worksheet_status_vehicle_arrived'), visible: true },
            { value: WorksheetStatus.UnderReview, caption: this.translate.instant('worksheet_status_under_review'), visible: true },
            { value: WorksheetStatus.WaitingWarehousePricing, caption: this.translate.instant('worksheet_status_waiting_warehouse_pricing'), visible: true },
            { value: WorksheetStatus.WaitingManagerPricing, caption: this.translate.instant('worksheet_status_waiting_manager_pricing'), visible: true },
            { value: WorksheetStatus.WaitingConfirmation, caption: this.translate.instant('worksheet_status_waiting_confirmation'), visible: true },
            { value: WorksheetStatus.WaitingRepairParts, caption: this.translate.instant('worksheet_status_waiting_repair_parts'), visible: true },
            { value: WorksheetStatus.WaitingRepair, caption: this.translate.instant('worksheet_status_waiting_repair'), visible: true },
            { value: WorksheetStatus.UnderRepair, caption: this.translate.instant('worksheet_status_under_repair'), visible: true },
            { value: WorksheetStatus.WaitingCompletion, caption: this.translate.instant('worksheet_status_waiting_completion'), visible: true },
            { value: WorksheetStatus.WaitingPayment, caption: this.translate.instant('worksheet_status_waiting_payment'), visible: true },
            { value: WorksheetStatus.NeedsReturning, caption: this.translate.instant('worksheet_status_needs_returning'), visible: deliveryEnabled },
            { value: WorksheetStatus.ReturningVehicle, caption: this.translate.instant('worksheet_status_returning_vehicle'), visible: deliveryEnabled },
            { value: WorksheetStatus.VehicleReturned, caption: this.translate.instant('worksheet_status_vehicle_returned'), visible: deliveryEnabled },
            { value: WorksheetStatus.Finished, caption: this.translate.instant('worksheet_status_finished'), visible: true },
            { value: WorksheetStatus.Cancelled, caption: this.translate.instant('worksheet_status_cancelled'), visible: true }
        ]);
        this.facilityTypeCategories = new List<SelectOption>([
            { value: FacilityTypeCategory.Repair, caption: this.translate.instant('facility_type_category_repair') },
            { value: FacilityTypeCategory.Hull, caption: this.translate.instant('facility_type_category_hull') },
            { value: FacilityTypeCategory.Washing, caption: this.translate.instant('facility_type_category_washing') },
            { value: FacilityTypeCategory.Management, caption: this.translate.instant('facility_type_category_management') }
        ]);
        this.vehicleFuelTypes = new List<SelectOption>([
            { value: VehicleFuelType.Gasoline, caption: this.translate.instant('vehicle_fuel_type_gasoline') },
            { value: VehicleFuelType.Diesel, caption: this.translate.instant('vehicle_fuel_type_diesel') },
            { value: VehicleFuelType.Hybrid, caption: this.translate.instant('vehicle_fuel_type_hybrid') },
            { value: VehicleFuelType.Electronic, caption: this.translate.instant('vehicle_fuel_type_electronic') },
            { value: VehicleFuelType.Gas, caption: this.translate.instant('vehicle_fuel_type_gas') }
        ]);
    }

    // endregion

    // region Methods

    /*
     * Awaitable function to get translation
     */
    get(key: string): Promise<string> {
        return new Promise(resolve => {
            this.translate.get(key)
                .subscribe(
                    resolve
                );
        });
    }

    getEditorStylingMode(): string {
        // DevExtreme editor style options: 'underlined', 'filled', 'outlined'
        return this.platform.isMobile ? 'underlined' : 'outlined';
    }

    // endregion
}
