import { ElementRef } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { FileDownloadResult } from '@services/backend/models/custom-models';
import { NotificationService } from '@services/notification/notification.service';
import { DxScrollViewComponent } from 'devextreme-angular';

export class Utils {

    // region stringFormat

    /**
     * String.Format, based on:
     * https://stackoverflow.com/questions/610406/javascript-equivalent-to-printf-string-format
     */
    static stringFormat(formatString: string, ...formatArgs: Array<string>): string {
        return formatString.replace(/{(\d+)}/g, (match, index) => typeof formatArgs[index] != 'undefined' ? formatArgs[index] : match);
    }

    // endregion

    // region Blob

    /*
     * For explanation visit:
     * https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
     */
    static b64toBlob(b64Data, contentType, sliceSize = 512): Blob {
        let byteCharacters = atob(b64Data);
        let byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);

            let byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            let byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    }

    static download(fileContent: Blob, fileName: string): void {
        let a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(fileContent);
        a.download = fileName;

        // Append anchor to body.
        document.body.appendChild(a);

        a.click();

        // Remove anchor from body
        document.body.removeChild(a);
        return;
    }

    static toBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onload = () => resolve(btoa(reader.result as string));
            reader.onerror = reject;
        });
    }

    // endregion

    static setAllPropertyNull(object: any) {
        for (let property in object) {
            if (!object.hasOwnProperty(property)) {
                continue;
            }
            object[property] = null;
        }
    }

    static checkAllPropertyNull(object: any): boolean {
        if (object == null) {
            return false;
        }
        for (let property in object) {
            if (!object.hasOwnProperty(property)) {
                continue;
            }
            if (object[property] != null) {
                if (typeof object[property] == 'object') {
                    if (!Utils.checkAllPropertyNull(object[property])) {
                        return false;
                    }
                }
                else {
                    return false;
                }
            }
        }
        return true;
    }

    static shallowCopy<T>(object: T): T {
        return { ...object };
    }

    static deepCopy<T>(obj: T): T {
        let copy;

        // Handle the 3 simple types, and null or undefined
        if (obj == null || typeof obj != 'object') {
            return obj;
        }

        // Handle Date
        if (obj instanceof Date) {
            copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }

        // Handle Array
        if (obj instanceof Array) {
            copy = [];
            for (let i = 0, len = obj.length; i < len; i++) {
                copy[i] = Utils.deepCopy(obj[i]);
            }
            return copy;
        }

        // Handle Object
        if (obj instanceof Object) {
            copy = {};
            for (let attr in obj) {
                if (obj.hasOwnProperty(attr)) {
                    copy[attr] = Utils.deepCopy(obj[attr]);
                }
            }
            return copy;
        }
    }

    static getUrlFromSegments(segments: Array<UrlSegment>, initialValue: string = ''): string | null {
        let url: string = segments.reduce((path, currentSegment) => path + '/' + currentSegment.path, initialValue);
        return url != initialValue ? url : null;
    }

    static getPreviousRoute(activatedRoute: ActivatedRoute): string {
        let route: string = '';

        // Handle when the last url array is empty (in case of child routes)
        let length: number = activatedRoute.snapshot.pathFromRoot.length;
        if (activatedRoute.snapshot.pathFromRoot[length - 1].url.length <= 0) {
            length = length - 1;
        }

        // Build previous route
        activatedRoute.snapshot.pathFromRoot
            .splice(0, length - 1)
            .forEach(item => {
                if (item.url.length > 0) {
                    route += '/' + item.url.map(segment => segment.path).join('/');
                }
            });

        return route;
    }

    static assignFieldValues(fromObject: any, toObject: any) {
        for (let property in fromObject) {
            if (!fromObject.hasOwnProperty(property) || !toObject.hasOwnProperty(property)) {
                continue;
            }
            toObject[property] = fromObject[property];
        }
    }

    static firstCharToUpper(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    static firstCharToLower(str: string): string {
        return str.charAt(0).toLowerCase() + str.slice(1);
    }

    static scrollToActiveElement(element: Element, scrollView: DxScrollViewComponent, padding: number = 100) {
        let rect = element.getBoundingClientRect();
        let scrollRect = scrollView.instance.element().getBoundingClientRect();

        let nativeElement: HTMLDivElement = scrollView.instance.element().querySelector('.dx-scrollable-container');

        if (rect.bottom + padding > scrollRect.bottom) {
            nativeElement.scrollBy({ top: rect.bottom + padding - scrollRect.bottom, behavior: 'smooth' });
        }
        else if (rect.top - padding < scrollRect.top) {
            nativeElement.scrollBy({ top: scrollRect.top - rect.top - padding, behavior: 'smooth' });
        }
    }

    static sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    static getContrastYIQ(color: string) {
        let hexcolor = '000000';
        let r = 0;
        let g = 0;
        let b = 0;

        if (color.charAt(0) !== '#') {
            let tempColor = color.split('(')[1].split(')')[0];
            let tempColorArray = tempColor.split(',');

            r = parseInt(tempColorArray[0], 10);
            g = parseInt(tempColorArray[1], 10);
            b = parseInt(tempColorArray[2], 10);
        } else {
            hexcolor = color.replace('#', '');
            r = parseInt(hexcolor.substr(0, 2), 16);
            g = parseInt(hexcolor.substr(2, 2), 16);
            b = parseInt(hexcolor.substr(4, 2), 16);
        }

        let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1500;
        return (yiq >= 128) ? 'black' : 'white';
    }

    /**
     * This method stops the swiper js swiper component from propagating its touch events
     * as it causes the devextreme package to throw errors as they use the same events
     */
    static stopSwiperTouchEventPropagation(swiperRef: ElementRef | undefined): void {
        if (!swiperRef){ return; }

        let domElement = swiperRef?.nativeElement as HTMLElement;
        domElement.addEventListener("touchstart", (event) => {
            if(this.isCustomEvent(event)){
                event.stopPropagation();
            }
        });
        domElement.addEventListener("touchend", (event) => {
            if(this.isCustomEvent(event)){
                event.stopPropagation();
            }
        });
        domElement.addEventListener("touchmove", (event) => {
            if(this.isCustomEvent(event)){
                event.stopPropagation();
            }
        });
    }
    
    static isCustomEvent(event: Event): event is CustomEvent {
        return event instanceof CustomEvent;
    }
}
