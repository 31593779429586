import { Injectable } from '@angular/core';
import { ServiceUserType } from '@services/backend/models/generated-models';
import { Dictionary, IDictionary } from 'linq-collections';
import { Entities } from '../cache-manager/cache-manager.interfaces';

enum PermissionType {
    None = 0,
    View = 1,
    Modify = 2
}

@Injectable({ providedIn: 'root' })
export class PermissionService {

    // region Fields

    private entityPermissions: IDictionary<ServiceUserType, IDictionary<string, PermissionType>>;

    // endregion

    // region Initialization

    constructor() {
        this.entityPermissions = new Dictionary<ServiceUserType, Dictionary<string, PermissionType>>([
            {
                key: ServiceUserType.Manager,
                value: new Dictionary<string, PermissionType>([
                    { key: Entities.UserProfile, value: PermissionType.Modify },
                    { key: Entities.UserSetting, value: PermissionType.Modify },
                    { key: Entities.UserManagement, value: PermissionType.Modify },
                    { key: Entities.FacilityTypes, value: PermissionType.Modify },
                    { key: Entities.Facilities, value: PermissionType.Modify },
                    { key: Entities.Appointments, value: PermissionType.Modify },
                    { key: Entities.Calendar, value: PermissionType.Modify },
                    { key: Entities.Service, value: PermissionType.Modify },
                    { key: Entities.ServiceSetting, value: PermissionType.Modify },
                    { key: Entities.FleetSetting, value: PermissionType.None },
                    { key: Entities.Account, value: PermissionType.Modify },
                    { key: Entities.RepairedMakes, value: PermissionType.Modify },
                    { key: Entities.ServicePartners, value: PermissionType.Modify },
                    { key: Entities.FinishedWorksheets, value: PermissionType.View },
                    { key: Entities.Worksheets, value: PermissionType.Modify },
                    { key: Entities.AppointmentWorksheets, value: PermissionType.Modify },
                    { key: Entities.Customers, value: PermissionType.Modify },
                    { key: Entities.ServiceCars, value: PermissionType.Modify },
                    { key: Entities.Cars, value: PermissionType.None },
                    { key: Entities.FleetCustomers, value: PermissionType.None }
                ])
            },
            {
                key: ServiceUserType.Mechanic,
                value: new Dictionary<string, PermissionType>([
                    { key: Entities.UserProfile, value: PermissionType.Modify },
                    { key: Entities.UserSetting, value: PermissionType.Modify },
                    { key: Entities.UserManagement, value: PermissionType.None },
                    { key: Entities.FacilityTypes, value: PermissionType.None },
                    { key: Entities.Facilities, value: PermissionType.View },
                    { key: Entities.Appointments, value: PermissionType.View },
                    { key: Entities.Calendar, value: PermissionType.View },
                    { key: Entities.Service, value: PermissionType.None },
                    { key: Entities.ServiceSetting, value: PermissionType.None },
                    { key: Entities.FleetSetting, value: PermissionType.None },
                    { key: Entities.Account, value: PermissionType.None },
                    { key: Entities.RepairedMakes, value: PermissionType.None },
                    { key: Entities.ServicePartners, value: PermissionType.None },
                    { key: Entities.FinishedWorksheets, value: PermissionType.None },
                    { key: Entities.Worksheets, value: PermissionType.Modify },
                    { key: Entities.AppointmentWorksheets, value: PermissionType.None },
                    { key: Entities.Customers, value: PermissionType.None },
                    { key: Entities.ServiceCars, value: PermissionType.None },
                    { key: Entities.Cars, value: PermissionType.None },
                    { key: Entities.FleetCustomers, value: PermissionType.None }
                ])
            },
            {
                key: ServiceUserType.Warehouse,
                value: new Dictionary<string, PermissionType>([
                    { key: Entities.UserProfile, value: PermissionType.Modify },
                    { key: Entities.UserSetting, value: PermissionType.Modify },
                    { key: Entities.UserManagement, value: PermissionType.None },
                    { key: Entities.FacilityTypes, value: PermissionType.None },
                    { key: Entities.Facilities, value: PermissionType.None },
                    { key: Entities.Appointments, value: PermissionType.None },
                    { key: Entities.Calendar, value: PermissionType.View },
                    { key: Entities.Service, value: PermissionType.None },
                    { key: Entities.ServiceSetting, value: PermissionType.None },
                    { key: Entities.FleetSetting, value: PermissionType.None },
                    { key: Entities.Account, value: PermissionType.None },
                    { key: Entities.RepairedMakes, value: PermissionType.None },
                    { key: Entities.ServicePartners, value: PermissionType.None },
                    { key: Entities.FinishedWorksheets, value: PermissionType.None },
                    { key: Entities.Worksheets, value: PermissionType.Modify },
                    { key: Entities.AppointmentWorksheets, value: PermissionType.None },
                    { key: Entities.Customers, value: PermissionType.View },
                    { key: Entities.ServiceCars, value: PermissionType.View },
                    { key: Entities.Cars, value: PermissionType.None },
                    { key: Entities.FleetCustomers, value: PermissionType.None }
                ])
            },
            {
                key: ServiceUserType.Delivery,
                value: new Dictionary<string, PermissionType>([
                    { key: Entities.UserProfile, value: PermissionType.Modify },
                    { key: Entities.UserSetting, value: PermissionType.Modify },
                    { key: Entities.UserManagement, value: PermissionType.None },
                    { key: Entities.FacilityTypes, value: PermissionType.None },
                    { key: Entities.Facilities, value: PermissionType.None },
                    { key: Entities.Appointments, value: PermissionType.None },
                    { key: Entities.Calendar, value: PermissionType.None },
                    { key: Entities.Service, value: PermissionType.None },
                    { key: Entities.ServiceSetting, value: PermissionType.None },
                    { key: Entities.FleetSetting, value: PermissionType.None },
                    { key: Entities.Account, value: PermissionType.None },
                    { key: Entities.RepairedMakes, value: PermissionType.None },
                    { key: Entities.ServicePartners, value: PermissionType.None },
                    { key: Entities.FinishedWorksheets, value: PermissionType.None },
                    { key: Entities.Worksheets, value: PermissionType.View },
                    { key: Entities.AppointmentWorksheets, value: PermissionType.None },
                    { key: Entities.Customers, value: PermissionType.None },
                    { key: Entities.ServiceCars, value: PermissionType.None },
                    { key: Entities.Cars, value: PermissionType.None },
                    { key: Entities.FleetCustomers, value: PermissionType.None }
                ])
            },
            {
                key: ServiceUserType.FleetManager,
                value: new Dictionary<string, PermissionType>([
                    { key: Entities.UserProfile, value: PermissionType.Modify },
                    { key: Entities.FleetProfile, value: PermissionType.Modify },
                    { key: Entities.UserSetting, value: PermissionType.Modify },
                    { key: Entities.UserManagement, value: PermissionType.None },
                    { key: Entities.FacilityTypes, value: PermissionType.None },
                    { key: Entities.Facilities, value: PermissionType.None },
                    { key: Entities.Appointments, value: PermissionType.View },
                    { key: Entities.Calendar, value: PermissionType.View },
                    { key: Entities.Service, value: PermissionType.None },
                    { key: Entities.ServiceSetting, value: PermissionType.None },
                    { key: Entities.FleetSetting, value: PermissionType.Modify },
                    { key: Entities.Account, value: PermissionType.None },
                    { key: Entities.RepairedMakes, value: PermissionType.None },
                    { key: Entities.ServicePartners, value: PermissionType.Modify },
                    { key: Entities.FinishedWorksheets, value: PermissionType.View },
                    { key: Entities.Worksheets, value: PermissionType.View },
                    { key: Entities.AppointmentWorksheets, value: PermissionType.View },
                    { key: Entities.Customers, value: PermissionType.None },
                    { key: Entities.ServiceCars, value: PermissionType.None },
                    { key: Entities.Cars, value: PermissionType.Modify },
                    { key: Entities.FleetCustomers, value: PermissionType.View }
                ])
            }
        ]);
    }

    getViewPermission(entity: Entities, type: ServiceUserType): boolean {
        let persmissions = this.entityPermissions.get(type);
        if (persmissions.containsKey(entity)) {
            let permission = persmissions.get(entity);
            return permission == PermissionType.View || permission == PermissionType.Modify;
        }
        return false;
    }

    getModifyPermission(entity: Entities, type: ServiceUserType): boolean {
        let persmissions = this.entityPermissions.get(type);
        if (persmissions.containsKey(entity)) {
            let permission = persmissions.get(entity);
            return permission == PermissionType.Modify;
        }
        return false;
    }

    // endregion
}
