import { Injectable } from '@angular/core';
import { Dictionary, IDictionary } from 'linq-collections';
import { BackendService } from '../backend/backend.service';
import { ListSettingDataDto } from '../backend/models/generated-models';
import { Entities } from '../cache-manager/cache-manager.interfaces';

@Injectable({ providedIn: 'root' })
export class ListSettingService {

    // region Init

    constructor(private backend: BackendService) {
        this.init();
    }

    init() {
        this.settingId = null;
        this.listSettings = new Dictionary<Entities, string>();

        // TODO add additional entities here
        this.settingPropertyNames = new Dictionary<Entities, string>([
            { key: Entities.UserManagement, value: 'userManagementLayout'},
            { key: Entities.FacilityTypes, value: 'facilityTypesLayout'},
            { key: Entities.Facilities, value: 'facilitiesLayout'},
            { key: Entities.Appointments, value: 'appointmentsLayout'},
            { key: Entities.AppointmentWorksheets, value: 'appointmentWorksheetsLayout'},
            { key: Entities.FinishedWorksheets, value: 'finishedWorksheetsLayout'},
            { key: Entities.Customers, value: 'customersLayout'},
            { key: Entities.ServiceCars, value: 'serviceCarsLayout'},
            { key: Entities.Cars, value: 'carsLayout'},
            { key: Entities.FleetCustomers, value: 'fleetCustomersLayout'},
            { key: Entities.ServicePartners, value: 'servicePartnersLayout'},
            { key: Entities.Calendar, value: 'calendarLayout'}
        ]);
    }

    initSettings(setting: ListSettingDataDto) {
        this.settingId = setting.id;

        this.listSettings = new Dictionary<Entities, string>();
        this.listSettings.set(Entities.UserManagement, setting.userManagementLayout);
        this.listSettings.set(Entities.FacilityTypes, setting.facilityTypesLayout);
        this.listSettings.set(Entities.Facilities, setting.facilitiesLayout);
        this.listSettings.set(Entities.Appointments, setting.appointmentsLayout);
        this.listSettings.set(Entities.AppointmentWorksheets, setting.appointmentWorksheetsLayout);
        this.listSettings.set(Entities.FinishedWorksheets, setting.finishedWorksheetsLayout);
        this.listSettings.set(Entities.Customers, setting.customersLayout);
        this.listSettings.set(Entities.ServiceCars, setting.serviceCarsLayout);
        this.listSettings.set(Entities.Cars, setting.carsLayout);
        this.listSettings.set(Entities.FleetCustomers, setting.fleetCustomersLayout);
        this.listSettings.set(Entities.ServicePartners, setting.servicePartnersLayout);
        this.listSettings.set(Entities.Calendar, setting.calendarLayout);
    }

    // endregion

    // region List Settings

    private settingId: string;
    private listSettings: IDictionary<Entities, string>;
    private settingPropertyNames: IDictionary<Entities, string>;

    getListSetting(key: Entities): string {
        return this.listSettings.get(key);
    }

    updateListSetting(key: Entities, value: string) {
        let oldValue = this.listSettings.get(key);
        if (value != oldValue) {
            this.listSettings.setOrUpdate(key, value);
            this.backend.UpdateListSetting({
                id: this.settingId,
                operations: [{
                    op: 'replace',
                    path: '/' + this.settingPropertyNames.get(key),
                    value: value
                }]
            })
                .subscribe();
        }
    }

    // endregion

}
