
// region Request base

export interface ListRequestArgs {
    skip?: number;
    take?: number;
    sortBy?: string;
    sortDescending?: boolean;
}

export interface RequestWithId {
    id: string;
}

interface UpdateRequest {
    timestamp: string;
}

interface PatchOperation {
    op: string;
    path: string;
    value: any;
}

export interface PatchRequest extends RequestWithId {
    operations: Array<PatchOperation>;
}

// endregion

// region Common Args and Results

export interface GetArgs extends RequestWithId {
}

export interface AddResult {
    id: string;
}

export interface UpdateResult {
}

export interface DeleteArgs extends RequestWithId {
}

export interface DeleteResult {
}

export interface FileDownloadResult {
    fileName: string;
    file: File;
}

// endregion

export interface GetServiceArgs extends GetArgs {
    makeId: string;
    year: number;
}

export enum FacilityDurationUnit {
    Minutes = 'minutes',
    Hours = 'hours',
    Days = 'days'
}
