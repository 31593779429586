import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export class TranslateConfig {
    static readonly translateFilePath = './assets/locale/';
    static readonly translateFileExtension = '.json';

    private static readonly translatePrefix = TranslateConfig.translateFilePath + 'ndru-';

    static init(translate: TranslateService, locale: string): Promise<any> {
        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang(locale);

        // return translate.use(translate.getBrowserLang()).toPromise();
        return translate.use(locale).toPromise();
    }

    static HttpLoaderFactory(http: HttpClient) {
        return new TranslateHttpLoader(http, TranslateConfig.translatePrefix, TranslateConfig.translateFileExtension);
    }
}
