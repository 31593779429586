import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AppConfigService {

    private config: any;

    constructor(private http: HttpClient) { }

    load(): Promise<any> {
        return this.http.get('/assets/config/app.config.json')
            .toPromise()
            .then(data => this.config = data);
    }

    getServerAddress() {
        if (!this.config) {
            throw Error('Config file not loaded!');
        }

        return this.config.serverAddress;
    }

    getGoogleMapsApiKey(): string {
        if (!this.config) {
            throw Error('Config file not loaded!');
        }

        return this.config.googleMapsApiKey;
    }
}
