import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dictionary } from 'linq-collections';

@Injectable({ providedIn: 'root' })
export class HttpCache {

    // region Dictionary

    /**
     * Example:
     * [OuterDictionary]{
     *   "GetServerTimezone" => [InnerDictionary]{
     *     "GetServerTimezone?q=0000" => [HttpResponse],
     *     "GetServerTimezone?q=1111" => [HttpResponse],
     *   },
     *   "AnotherRequest" => [InnerDictionary]{
     *     "AnotherRequest?q=0000" => [HttpResponse],
     *   },
     * }
     */
    dictionary: Dictionary<string, Dictionary<string, HttpResponse<any>>> = new Dictionary<string, Dictionary<string, HttpResponse<any>>>();

    // endregion

    // region Get...
    /**
     * Returns a cached response, if any, or null if not present.
     */
    get(requestName: string, request: HttpRequest<any>): HttpResponse<any> | null {
        let groupDictionary = this.getRequestGroup(requestName);
        if (!groupDictionary) {
            return null;
        }

        let key = this.calcKey(request);
        return groupDictionary.containsKey(key) ? groupDictionary.get(key) : null;
    }

    /**
     * Returns a cached response, if any, or null if not present.
     */
    getRequestGroup(requestName: string): Dictionary<string, HttpResponse<any>> | null {
        requestName = requestName || '';
        return this.dictionary.containsKey(requestName) ? this.dictionary.get(requestName) : null;
    }

    // endregion

    /**
     * Adds or updates the response in the cache.
     */
    put(requestName: string, request: HttpRequest<any>, response: HttpResponse<any>): string {
        let key = this.calcKey(request);
        let groupDictionary = this.getRequestGroup(requestName) || new Dictionary<string, HttpResponse<any>>();
        groupDictionary.setOrUpdate(key, response);

        this.dictionary.setOrUpdate(requestName, groupDictionary);

        return key;
    }

    private calcKey(request: HttpRequest<any>): string {
        return JSON.stringify(request.urlWithParams);
    }

    // region Remove request

    /**
     * Removed everything
     */
    clear() {
        this.dictionary = new Dictionary<string, Dictionary<string, HttpResponse<any>>>();
    }

    /**
     * Removes all the requests with the given name, if any.
     * For more information, see the private field "dictionary"
     */
    removeRequestGroup(requestName: string): void {
        if (this.dictionary.containsKey(requestName)) {
            this.dictionary.remove(requestName);
        }
    }

    // endregion
}
