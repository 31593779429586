export class Constants {
    static readonly pageSize: number = 100;
    static readonly defaultLanguage = 'hu-HU';

    static readonly numberPrecision: number = 2;
    static readonly currencyFormat: string = '#0.00';

    static readonly applicationFee: number = 0.05;

    static readonly mobileRowTemplate: string = 'mobileRowTemplate';

    static readonly allMakeId: string = 'af28ddf7-3547-49be-81d1-b8a2bb74923b';
}
