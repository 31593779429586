import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@services/guard/auth-guard.service';

const routes: Routes = [
    { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
    { path: 'confirm', loadChildren: () => import('./pages/confirm/confirm.module').then(m => m.ConfirmModule) },
    { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule), canLoad: [AuthGuard] },
    { path: 'error', loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorModule) },
    { path: '**', redirectTo: 'error' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
