import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

const defaultTimeout: number = 30000;
@Injectable()
export class TimeOutInterceptor implements HttpInterceptor {    

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {    
        
        const timeoutValueNumeric = Number(defaultTimeout);
        return next.handle(request).pipe(timeout(timeoutValueNumeric));        
    }    
}
