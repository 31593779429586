import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { PlatformService } from '@services/platform/platform.service';
import { Utils } from '@shared/utils';

@Injectable({ providedIn: 'root' })
export class PageReuseStrategy implements RouteReuseStrategy {

    constructor(
        private platform: PlatformService
    ) {
    }

    storedRouteHandles = new Map<string, DetachedRouteHandle>();

    // Decides if the route should be stored
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        if (this.platform.isMobile) {
            return false;
        }
        else {
            return route.data.shouldReuse;
        }
    }

    // Store the information for the route we're destructing
    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        this.storedRouteHandles.set(this.getKeyFromRoute(route), handle);
    }

    // Return true if we have a stored route object for the next route
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return !!this.storedRouteHandles.get(this.getKeyFromRoute(route));
    }

    // If we returned true in shouldAttach(), now return the actual route data for restoration
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        return this.storedRouteHandles.get(this.getKeyFromRoute(route));
    }

    // Reuse the route if we're going to and from the same route
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.routeConfig === curr.routeConfig;
    }

    private getKeyFromRoute(route: ActivatedRouteSnapshot): string {
        let url = Utils.getUrlFromSegments(route.url);
        return route.data.entity + (url != null ? url : '');
    }

    clear() {
        this.storedRouteHandles.clear();
    }
}
