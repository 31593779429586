import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomHeaders } from '@shared/custom-headers';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { InterceptorMethods } from './interceptor-methods';

@Injectable()
export class ResponseTimeInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!InterceptorMethods.shouldIntercept(request)) {
            return next.handle(request);
        }

        const started = Date.now();
        return next.handle(request)
            .pipe(
                tap(event => {
                    ResponseTimeInterceptor.calcResponseTime(event, started, request);
                })
            );
    }

    private static calcResponseTime(event: HttpEvent<any>, started: number, request: HttpRequest<any>) {
        if (!(event instanceof HttpResponse)) {
            return;
        }

        const totalProcessTime = Date.now() - started;

        let response = <HttpResponse<any>> event;
        let serverProcessTime: number = parseInt(response.headers.get(CustomHeaders.ResponseTime), 10);

        let timeReportMessage = `Request for [${request.method}] ${request.urlWithParams} took ${totalProcessTime} ms`;
        if (serverProcessTime) {
            const networkDelay = totalProcessTime - serverProcessTime;
            timeReportMessage = `${timeReportMessage} in total, where the server's process time was ${serverProcessTime} ms, and so the network delay was ${networkDelay} ms. `;
        }
        console.log(timeReportMessage);
    }
}
