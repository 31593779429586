import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@services/notification/notification.service';
import { environment } from '../../../environments/environment';
import * as Sentry from "@sentry/angular-ivy";

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {

    constructor(
        private injector: Injector
    ) {
        super();
    }

    handleError(error: Error) {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (chunkFailedMessage.test(error.message)) {
            window.location.reload();
        }
        else {
            const router = this.injector.get(Router);
            const zone = this.injector.get(NgZone);
            const notification = this.injector.get(NotificationService);
            zone.run(() => {
                super.handleError(error);
                Sentry.captureException(error);
                if (environment.production) {
                    notification.showClientError();
                    router.navigate(['/main']);
                }
            });
        }
    }
}
