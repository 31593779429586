<dx-popup
    [showTitle]="true"
    [title]="title"
    [width]="325"
    [height]="200"
    [visible]="popupVisible"
    (onHiding)="onHiding()">
    <div *dxTemplate="let data of 'content'">
        <div class="dialog-message">
            {{ message }}
        </div>

        <div *ngIf="buttonType == buttonTypeEnum.Confirm" class="button-container">
            <dx-button id="dialogConfirmButton" type="secondary" [text]="'common_cancel' | translate" (onClick)="closeClicked()"></dx-button>
            <dx-button id="dialogCancelButton" type="primary" [text]="(confirmText != null ? confirmText : 'common_confirm'  | translate)" (onClick)="successClicked()"></dx-button>
        </div>
        <div *ngIf="buttonType == buttonTypeEnum.YesNo" class="button-container">
            <dx-button [text]="'common_no' | translate" type="secondary" (onClick)="closeClicked()"></dx-button>
            <dx-button [text]="'common_yes' | translate" type="primary" (onClick)="successClicked()"></dx-button>
        </div>
        <div *ngIf="buttonType == buttonTypeEnum.Ok" class="button-container">
            <dx-button [text]="'common_ok' | translate" type="secondary" (onClick)="closeClicked()"></dx-button>
        </div>
    </div>
</dx-popup>
