export enum HubEvents {
    RecordCreated = 'RecordCreated',
    RecordUpdated = 'RecordUpdated',
    RecordDeleted = 'RecordDeleted'
}

export interface RecordMessage {
    entity: ServerEntities;
    id: string;
}

export enum ServerEntities {
    ServiceUser = 'ServiceUser',
    Appointment = 'Appointment',
    Customer = 'Customer',
    ServiceCar = 'ServiceCar',
    Facility = 'Facility',
    Worksheet = 'Worksheet'
}
