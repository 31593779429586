import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utils } from '@shared/utils';
import { Enumerable, IList } from 'linq-collections';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AppConfigService } from '../app-config/app-config.service';
import { InterceptorConstants as IC } from './interceptors/interceptor-constants';
import * as CustomModels from './models/custom-models';
import * as GeneratedModels from './models/generated-models';
import { Transforms } from './models/transforms';
import { HttpMethods, RequestData, RequestNames, Requests } from './requests';

@Injectable({ providedIn: 'root' })
export class BackendService {

    // region Fields

    private _baseUrl: string;

    private get baseUrl(): string {
        if (!this._baseUrl) {
            this._baseUrl = this.config.getServerAddress() + this.defaultApiEndpoint;
        }
        return this._baseUrl;
    }

    private readonly defaultApiEndpoint: string = '/api';

    // endregion

    // region Initialization

    constructor(
        private httpClient: HttpClient,
        private config: AppConfigService
    ) {
        this._baseUrl = null;
    }

    // endregion

    // region Send request

    private sendListRequest<TOutputModel>(requestName: string, filterArgs: any, additionalArgs: CustomModels.ListRequestArgs, noCache: boolean = false): Observable<IList<TOutputModel>> {
        let requestData: RequestData = Requests.get(requestName);
        let args: RequestAddParamArgs = {
            url: this.baseUrl + '/' + requestData.url,
            options: BackendService.getRequestOptions(requestName, noCache)
        };

        let params = new HttpParams();
        params = BackendService.addParams(params, filterArgs);
        params = BackendService.addParams(params, additionalArgs, true);
        args.options.params = params;

        let request = {
            data: requestData,
            args: args
        };

        return this.httpClient
            .request(request.data.method, request.args.url, request.args.options)
            .pipe(
                filter(httpEvent => httpEvent instanceof HttpResponse),
                map((httpResponse: HttpResponse<any>) => httpResponse.body),
                map(source => BackendService.mapResultList<TOutputModel>(source, requestData))
            );
    }

    private sendRequest<TOutputModel>(requestName: string, inputModel: any): Observable<TOutputModel> {
        let requestData: RequestData = Requests.get(requestName);
        if (inputModel.hasOwnProperty('id')) {
            requestData = BackendService.getFormattedRequestData(requestData, inputModel.id);
        }
        return this.sendRequestInternal<TOutputModel>(requestName, requestData, inputModel);
    }

    private sendUpdateRequest<TOutputModel>(requestName: string, inputModel: any, id: string): Observable<TOutputModel> {
        let requestData: RequestData = Requests.get(requestName);
        requestData = BackendService.getFormattedRequestData(requestData, id);
        return this.sendRequestInternal<TOutputModel>(requestName, requestData, inputModel);
    }

    private sendRequestInternal<TOutputModel>(requestName: string, requestData: RequestData, inputModel: any): Observable<TOutputModel> {
        let args: RequestAddParamArgs = {
            url: this.baseUrl + '/' + requestData.url,
            options: BackendService.getRequestOptions(requestName)
        };

        if (requestData.method == HttpMethods.POST || requestData.method == HttpMethods.PUT) {
            args.options.body = inputModel;
        }
        if (requestData.method == HttpMethods.GET && Object.keys(inputModel).length > 1) {
            delete inputModel.id;
            let params = new HttpParams();
            params = BackendService.addParams(params, inputModel);
            args.options.params = params;
        }

        let request = {
            data: requestData,
            args: args
        };

        return this.httpClient
            .request(request.data.method, request.args.url, request.args.options)
            .pipe(
                filter(httpEvent => httpEvent instanceof HttpResponse),
                map((httpResponse: HttpResponse<any>) => httpResponse.body),
                map(source => BackendService.mapResult<TOutputModel>(source, requestData))
            );
    }

    private sendPatchRequest<TOutputModel>(requestName: string, inputModel: CustomModels.PatchRequest): Observable<TOutputModel> {
        let requestData: RequestData = Requests.get(requestName);
        requestData = BackendService.getFormattedRequestData(requestData, inputModel.id);
        let args: RequestAddParamArgs = {
            url: this.baseUrl + '/' + requestData.url,
            options: BackendService.getRequestOptions(requestName)
        };

        args.options.body = inputModel.operations;

        let request = {
            data: requestData,
            args: args
        };

        return this.httpClient
            .request(request.data.method, request.args.url, request.args.options)
            .pipe(
                filter(httpEvent => httpEvent instanceof HttpResponse),
                map((httpResponse: HttpResponse<any>) => httpResponse.body),
                map(source => BackendService.mapResult<TOutputModel>(source, requestData))
            );
    }

    private sendRefreshTokenRequest(inputModel: any): Observable<HttpResponse<any>> {
        let requestName: string = RequestNames.RefreshAccessToken;
        let requestData: RequestData = Requests.get(requestName);
        let args: RequestAddParamArgs = {
            url: this.baseUrl + '/' + requestData.url,
            options: {
                observe: 'events',
                headers: new HttpHeaders()
                    .set(IC.RequestName, requestName),
                params: null,
                body: inputModel
            }
        };

        let request = {
            data: requestData,
            args: args
        };

        return this.httpClient
            .request(request.data.method, request.args.url, request.args.options)
            .pipe(
                filter(httpEvent => httpEvent instanceof HttpResponse)
            );
    }

    private sendDownloadRequest<TOutputModel>(requestName: string, id: string): Observable<TOutputModel> {
        let requestData: RequestData = Requests.get(requestName);
        requestData = BackendService.getFormattedRequestData(requestData, id);
        let args: RequestAddParamArgs = {
            url: this.baseUrl + '/' + requestData.url,
            options: BackendService.getRequestOptions(requestName)
        };

        // Set responseType otherwise angular tries to parse response as json
        args.options.responseType = 'blob';

        let request = {
            data: requestData,
            args: args
        };

        return this.httpClient
            .request(request.data.method, request.args.url, request.args.options)
            .pipe(
                map((httpEvent: any) => {
                    if (httpEvent instanceof HttpResponse) {
                        let file: CustomModels.FileDownloadResult = {
                            file: httpEvent.body, // this is a File type
                            fileName: httpEvent.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1] // Get the file name from the header
                        };
                        return BackendService.mapResult<TOutputModel>(file, requestData);
                    }
                    return null;
                }),
                filter(event => event != null) // This way the caller only gets the response
            );
    }
    private sendDownloadRequestWithBody<TOutputModel>(requestName: string, inputModel: GeneratedModels.SuzukiExportFilter): Observable<TOutputModel> {
        let requestData: RequestData = Requests.get(requestName);
        requestData = BackendService.getFormattedRequestData(requestData, null);
        
        let args: RequestAddParamArgs = {
            url: this.baseUrl + '/' + requestData.url,
            options: BackendService.getRequestOptions(requestName)
        };

        args.options.responseType = 'blob';
        args.options.body = inputModel;

        let request = {
            data: requestData,
            args: args
        };

        return this.httpClient
            .request(request.data.method, request.args.url, request.args.options)
            .pipe(
                map((httpEvent: any) => {
                    if (httpEvent instanceof HttpResponse) {
                        let file: CustomModels.FileDownloadResult = {
                            file: httpEvent.body,
                            fileName: httpEvent.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1] // Get the file name from the header
                        };
                        return BackendService.mapResult<TOutputModel>(file, requestData);
                    }
                    return null;
                }),
                filter(event => event != null) 
            );
    }

    private sendUploadRequest<TOutputModel>(requestName: string, id: string, file: File): Observable<TOutputModel> {
        let requestData: RequestData = Requests.get(requestName);
        requestData = BackendService.getFormattedRequestData(requestData, id);
        let args: RequestAddParamArgs = {
            url: this.baseUrl + '/' + requestData.url,
            options: BackendService.getRequestOptions(requestName)
        };

        let formData = new FormData();
        formData.append('file', file, file.name);

        let request = {
            data: requestData,
            args: args
        };

        return this.httpClient
            .request(new HttpRequest(request.data.method, request.args.url, formData, request.args.options))
            .pipe(
                map((httpEvent: any) => {
                    if (httpEvent instanceof HttpResponse) {
                        return BackendService.mapResult<TOutputModel>(httpEvent.body, requestData);
                    }
                    return null;
                }),
                filter(event => event != null) // This way the caller only gets the response
            );
    }

    // endregion

    // endregion

    // region Helpers

    private static getFormattedRequestData(requestTemplate: RequestData, id: string): RequestData {
        return {
            method: requestTemplate.method,
            url: Utils.stringFormat(requestTemplate.url, id),
            transform: requestTemplate.transform
        };
    }

    private static getRequestOptions(requestName: string, noCache: boolean = false): RequestOptions {
        let headers = new HttpHeaders()
            .set(IC.UseInterceptors, 'true')
            .set(IC.RequestName, requestName);
        if (noCache) {
            headers = headers.set(IC.NoCache, 'true');
        }

        return {
            observe: 'events',
            headers: headers,
            params: null,
            body: null,
            reportProgress: false
        };
    }

    private static addParams(params: HttpParams, inputModel: any, allowNullValues: boolean = false): HttpParams {
        for (let propertyName in inputModel) {
            if (!inputModel.hasOwnProperty(propertyName)) {
                continue;
            }

            let value = inputModel[propertyName];
            if (!allowNullValues) {
                // Need to check null explicitly, since it can be boolean value as well
                if (value == null) {
                    continue;
                }
                // Check for empty string
                if (typeof value == 'string' && value == '') {
                    continue;
                }
            }

            if (value instanceof Date) {
                value = value.toISOString();
            }
            else if (value instanceof Object) {
                let jsonValue = JSON.stringify(value);
                value = encodeURIComponent(jsonValue);
            }
            else if (value == null) {
                // Empty string will be correctly parsed as null
                value = '';
            }

            params = params.set(propertyName, value); // Immutable...
        }
        return params;
    }

    private static mapResult<TOutputModel>(source: any, requestData: RequestData): TOutputModel {
        if (requestData.transform != null) {
            return <TOutputModel> <any> requestData.transform(source);
        }
        else {
            return Transforms.defaultTransform<TOutputModel>(source);
        }
    }

    private static mapResultList<TOutputModel>(source: Array<any>, requestData: RequestData): IList<TOutputModel> {
        let enumerable = Enumerable.fromSource(source);

        if (requestData.transform != null) {
            enumerable = enumerable.select(item => requestData.transform(item));
        }

        return enumerable
            .select(item => <TOutputModel> <any> item)
            .toList();
    }

    // endregion

    // endregion

    // region Requests

    Login(inputModel: GeneratedModels.ServiceLoginRequest): Observable<CustomModels.UpdateResult> {
        return this.sendRequest<CustomModels.UpdateResult>(RequestNames.Login, inputModel);
    }

    RefreshAccessToken(inputModel: GeneratedModels.RefreshAccessTokenRequest): Observable<HttpResponse<any>> {
        return this.sendRefreshTokenRequest(inputModel);
    }

    ConfirmRegistration(inputModel: GeneratedModels.ConfirmRegistrationRequest): Observable<CustomModels.UpdateResult> {
        return this.sendRequest<CustomModels.UpdateResult>(RequestNames.ConfirmRegistration, inputModel);
    }

    SendConfirmRegistrationLink(inputModel: GeneratedModels.SendConfirmRegistrationLinkRequest): Observable<CustomModels.UpdateResult> {
        return this.sendRequest<CustomModels.UpdateResult>(RequestNames.SendConfirmRegistrationLink, inputModel);
    }

    ResetServicePassword(inputModel: GeneratedModels.ResetPasswordRequest): Observable<CustomModels.UpdateResult> {
        return this.sendRequest<CustomModels.UpdateResult>(RequestNames.ResetServicePassword, inputModel);
    }

    GetListSetting(id: string): Observable<GeneratedModels.ListSettingDataDto> {
        return this.sendRequest<GeneratedModels.ListSettingDataDto>(RequestNames.GetListSetting, { id: id });
    }

    UpdateListSetting(inputModel: CustomModels.PatchRequest): Observable<CustomModels.UpdateResult> {
        return this.sendPatchRequest<CustomModels.UpdateResult>(RequestNames.UpdateListSetting, inputModel);
    }

    GetMakes(): Observable<IList<GeneratedModels.MakeDataDto>> {
        return this.sendListRequest<GeneratedModels.MakeDataDto>(RequestNames.GetMakes, {}, {});
    }

    GetServiceCarImage(inputModel: CustomModels.GetArgs): Observable<GeneratedModels.CarImageDto> {
        return this.sendRequest<GeneratedModels.CarImageDto>(RequestNames.GetServiceCarImage, inputModel);
    }

    GetCarImage(inputModel: CustomModels.GetArgs): Observable<GeneratedModels.CarImageDto> {
        return this.sendRequest<GeneratedModels.CarImageDto>(RequestNames.GetCarImage, inputModel);
    }

    GetUsers(filterArgs: GeneratedModels.ServiceEmployeeFilter, additionalArgs: CustomModels.ListRequestArgs): Observable<IList<GeneratedModels.ServiceEmployeeListDto>> {
        return this.sendListRequest<GeneratedModels.ServiceEmployeeListDto>(RequestNames.GetUsers, filterArgs, additionalArgs);
    }

    SelectUsers(filterArgs: GeneratedModels.ServiceEmployeeFilter, additionalArgs: CustomModels.ListRequestArgs): Observable<IList<GeneratedModels.ServiceEmployeeSelectDto>> {
        return this.sendListRequest<GeneratedModels.ServiceEmployeeSelectDto>(RequestNames.SelectUsers, filterArgs, additionalArgs);
    }

    GetUser(inputModel: CustomModels.GetArgs): Observable<GeneratedModels.ServiceEmployeeDataDto> {
        return this.sendRequest<GeneratedModels.ServiceEmployeeDataDto>(RequestNames.GetUser, inputModel);
    }

    AddUser(inputModel: GeneratedModels.ModifyServiceEmployeeDto): Observable<CustomModels.AddResult> {
        return this.sendRequest<CustomModels.AddResult>(RequestNames.AddUser, inputModel);
    }

    UpdateUser(inputModel: GeneratedModels.ModifyServiceEmployeeDto, id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.UpdateUser, inputModel, id);
    }

    DeleteUser(inputModel: CustomModels.DeleteArgs): Observable<CustomModels.DeleteResult> {
        return this.sendRequest<CustomModels.DeleteResult>(RequestNames.DeleteUser, inputModel);
    }

    GetCurrentUserData(id: string): Observable<GeneratedModels.ServiceEmployeeDataDto> {
        return this.sendRequest<GeneratedModels.ServiceEmployeeDataDto>(RequestNames.GetCurrentUserData, { id: id });
    }

    RegisterUser(inputModel: GeneratedModels.RegisterServiceUserDto): Observable<CustomModels.UpdateResult> {
        return this.sendRequest<CustomModels.UpdateResult>(RequestNames.RegisterUser, inputModel);
    }

    GetMenuInfoCount(): Observable<GeneratedModels.MenuInfoCountDto> {
        return this.sendRequest<GeneratedModels.MenuInfoCountDto>(RequestNames.GetMenuInfoCount, {});
    }

    GetFaultSuggestions(): Observable<GeneratedModels.GetFaultSuggestionsResult> {
        return this.sendRequest<GeneratedModels.GetFaultSuggestionsResult>(RequestNames.GetFaultSuggestions, {});
    }

    GetServiceUserNotifications(filterArgs: GeneratedModels.ServiceUserNotificationFilter): Observable<IList<GeneratedModels.ServiceUserNotificationListDto>> {
        return this.sendListRequest<GeneratedModels.ServiceUserNotificationListDto>(RequestNames.GetServiceUserNotifications, filterArgs, {});
    }

    SetServiceUserNotificationSeen(id: string): Observable<CustomModels.UpdateResult> {
        return this.sendRequest<CustomModels.UpdateResult>(RequestNames.SetServiceUserNotificationSeen, { id: id });
    }

    GetCalendarEmployees(): Observable<IList<GeneratedModels.ServiceEmployeeDataDto>> {
        return this.sendListRequest<GeneratedModels.ServiceEmployeeDataDto>(RequestNames.GetCalendarEmployees, {}, {});
    }

    ReorderServiceEmployees(inputModel: GeneratedModels.ReorderArgs): Observable<CustomModels.UpdateResult> {
        return this.sendRequest<CustomModels.UpdateResult>(RequestNames.ReorderServiceEmployees, inputModel);
    }

    GetUserSetting(): Observable<GeneratedModels.ServiceUserSettingDataDto> {
        return this.sendRequest<GeneratedModels.ServiceUserSettingDataDto>(RequestNames.GetUserSetting, {});
    }

    UpdateUserSetting(inputModel: GeneratedModels.UpdateServiceUserSettingDto, id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.UpdateUserSetting, inputModel, id);
    }

    GetUserProfile(): Observable<GeneratedModels.ServiceUserProfileDataDto> {
        return this.sendRequest<GeneratedModels.ServiceUserProfileDataDto>(RequestNames.GetUserProfile, {});
    }

    UpdateUserProfile(inputModel: GeneratedModels.UpdateServiceUserProfileDto, id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.UpdateUserProfile, inputModel, id);
    }
    
    UpdateUserPassword(inputModel: GeneratedModels.UpdateServiceUserProfileDto, id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.UpdateUserPassword, inputModel, id);
    }

    GetFleetManagerProfile(): Observable<GeneratedModels.FleetManagerProfileDataDto> {
        return this.sendRequest<GeneratedModels.FleetManagerProfileDataDto>(RequestNames.GetFleetManagerProfile, {});
    }

    UpdateFleetManagerProfile(inputModel: GeneratedModels.UpdateFleetManagerProfileDto, id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.UpdateFleetManagerProfile, inputModel, id);
    }

    CheckAccountSetUp(): Observable<GeneratedModels.CheckAccountSetUpDto> {
        return this.sendRequest<GeneratedModels.CheckAccountSetUpDto>(RequestNames.CheckAccountSetUp, {});
    }

    CreateAccountLink(): Observable<GeneratedModels.CreateAccountLinkDto> {
        return this.sendRequest<GeneratedModels.CreateAccountLinkDto>(RequestNames.CreateAccountLink, {});
    }

    CreateLoginLink(): Observable<GeneratedModels.CreateLoginLinkDto> {
        return this.sendRequest<GeneratedModels.CreateLoginLinkDto>(RequestNames.CreateLoginLink, {});
    }

    DeleteAccount(): Observable<CustomModels.DeleteResult> {
        return this.sendRequest<CustomModels.DeleteResult>(RequestNames.DeleteAccount, {});
    }

    SelectServices(filterArgs: GeneratedModels.ServiceFilter, additionalArgs: CustomModels.ListRequestArgs): Observable<IList<GeneratedModels.ServiceSelectDto>> {
        return this.sendListRequest<GeneratedModels.ServiceSelectDto>(RequestNames.SelectServices, filterArgs, additionalArgs);
    }

    GetService(inputModel: CustomModels.GetArgs): Observable<GeneratedModels.ServiceDataDto> {
        return this.sendRequest<GeneratedModels.ServiceDataDto>(RequestNames.GetService, inputModel);
    }

    GetAppService(inputModel: CustomModels.GetServiceArgs): Observable<GeneratedModels.AppServiceDataDto> {
        return this.sendRequest<GeneratedModels.AppServiceDataDto>(RequestNames.GetAppService, inputModel);
    }

    UpdateService(inputModel: GeneratedModels.ModifyServiceDto, id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.UpdateService, inputModel, id);
    }

    DeleteService(): Observable<CustomModels.DeleteResult> {
        return this.sendRequest<CustomModels.DeleteResult>(RequestNames.DeleteService, {});
    }

    GetServiceSetting(): Observable<GeneratedModels.ServiceSettingDataDto> {
        return this.sendRequest<GeneratedModels.ServiceSettingDataDto>(RequestNames.GetServiceSetting, {});
    }

    UpdateServiceSetting(inputModel: GeneratedModels.UpdateServiceSettingDto): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.UpdateServiceSetting, inputModel, null);
    }

    GetFleetManagerSetting(): Observable<GeneratedModels.FleetManagerSettingDataDto> {
        return this.sendRequest<GeneratedModels.FleetManagerSettingDataDto>(RequestNames.GetFleetManagerSetting, {});
    }

    UpdateFleetManagerSetting(inputModel: GeneratedModels.UpdateFleetManagerSettingDto): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.UpdateFleetManagerSetting, inputModel, null);
    }

    GetHourlyFees(): Observable<IList<GeneratedModels.HourlyFeeDataDto>> {
        return this.sendListRequest<GeneratedModels.HourlyFeeDataDto>(RequestNames.GetHourlyFees, {}, {});
    }

    SelectHourlyFees(filterArgs: GeneratedModels.HourlyFeeFilter, additionalArgs: CustomModels.ListRequestArgs): Observable<IList<GeneratedModels.HourlyFeeSelectDto>> {
        return this.sendListRequest<GeneratedModels.HourlyFeeSelectDto>(RequestNames.SelectHourlyFees, filterArgs, additionalArgs);
    }

    GetHourlyFee(inputModel: CustomModels.GetArgs): Observable<GeneratedModels.HourlyFeeDataDto> {
        return this.sendRequest<GeneratedModels.HourlyFeeDataDto>(RequestNames.GetHourlyFee, inputModel);
    }

    AddHourlyFee(inputModel: GeneratedModels.ModifyHourlyFeeDto): Observable<CustomModels.AddResult> {
        return this.sendRequest<CustomModels.AddResult>(RequestNames.AddHourlyFee, inputModel);
    }

    UpdateHourlyFee(inputModel: GeneratedModels.ModifyHourlyFeeDto, id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.UpdateHourlyFee, inputModel, id);
    }

    DeleteHourlyFee(inputModel: CustomModels.DeleteArgs): Observable<CustomModels.DeleteResult> {
        return this.sendRequest<CustomModels.DeleteResult>(RequestNames.DeleteHourlyFee, inputModel);
    }

    GetRepairedMakes(inputModel: CustomModels.GetArgs): Observable<GeneratedModels.ServiceMakesDataDto> {
        return this.sendRequest<GeneratedModels.ServiceMakesDataDto>(RequestNames.GetRepairedMakes, inputModel);
    }

    UpdateRepairedMakes(inputModel: GeneratedModels.ModifyServiceMakesDto, id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.UpdateRepairedMakes, inputModel, id);
    }

    GetAppointments(filterArgs: GeneratedModels.AppointmentFilter, additionalArgs: CustomModels.ListRequestArgs): Observable<IList<GeneratedModels.AppointmentListDto>> {
        return this.sendListRequest<GeneratedModels.AppointmentListDto>(RequestNames.GetAppointments, filterArgs, additionalArgs);
    }

    SelectAppointments(filterArgs: GeneratedModels.AppointmentFilter, additionalArgs: CustomModels.ListRequestArgs): Observable<IList<GeneratedModels.AppointmentSelectDto>> {
        return this.sendListRequest<GeneratedModels.AppointmentSelectDto>(RequestNames.SelectAppointments, filterArgs, additionalArgs);
    }

    GetAppointment(inputModel: CustomModels.GetArgs): Observable<GeneratedModels.AppointmentDataDto> {
        return this.sendRequest<GeneratedModels.AppointmentDataDto>(RequestNames.GetAppointment, inputModel);
    }

    AddAppointment(inputModel: GeneratedModels.ModifyAppointmentDto): Observable<CustomModels.AddResult> {
        return this.sendRequest<CustomModels.AddResult>(RequestNames.AddAppointment, inputModel);
    }

    UpdateAppointment(inputModel: GeneratedModels.ModifyAppointmentDto, id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.UpdateAppointment, inputModel, id);
    }

    DeleteAppointment(inputModel: CustomModels.DeleteArgs): Observable<CustomModels.DeleteResult> {
        return this.sendRequest<CustomModels.DeleteResult>(RequestNames.DeleteAppointment, inputModel);
    }

    GetCalendarAppointments(filterArgs: GeneratedModels.AppointmentFilter): Observable<IList<GeneratedModels.CalendarListDto>> {
        return this.sendListRequest<GeneratedModels.CalendarListDto>(RequestNames.GetCalendarAppointments, filterArgs, {});
    }

    BookAppointment(inputModel: GeneratedModels.BookAppointmentRequest): Observable<CustomModels.UpdateResult> {
        return this.sendRequest<CustomModels.UpdateResult>(RequestNames.BookAppointment, inputModel);
    }

    BookServiceAppointment(inputModel: GeneratedModels.BookServiceAppointmentRequest): Observable<CustomModels.UpdateResult> {
        return this.sendRequest<CustomModels.UpdateResult>(RequestNames.BookServiceAppointment, inputModel);
    }

    GetAvailableAppointments(inputModel: GeneratedModels.AvailableAppointmentsRequest): Observable<GeneratedModels.AvailableAppointmentsResult> {
        return this.sendRequest<GeneratedModels.AvailableAppointmentsResult>(RequestNames.GetAvailableAppointments, inputModel);
    }

    UpdateServiceAppointment(inputModel: GeneratedModels.UpdateAppointmentRequest): Observable<CustomModels.UpdateResult> {
        return this.sendRequest<CustomModels.UpdateResult>(RequestNames.UpdateServiceAppointment, inputModel);
    }

    GetFacilityTypes(filterArgs: GeneratedModels.FacilityTypeFilter, additionalArgs: CustomModels.ListRequestArgs): Observable<IList<GeneratedModels.FacilityTypeListDto>> {
        return this.sendListRequest<GeneratedModels.FacilityTypeListDto>(RequestNames.GetFacilityTypes, filterArgs, additionalArgs);
    }

    SelectFacilityTypes(filterArgs: GeneratedModels.FacilityTypeFilter, additionalArgs: CustomModels.ListRequestArgs): Observable<IList<GeneratedModels.FacilityTypeSelectDto>> {
        return this.sendListRequest<GeneratedModels.FacilityTypeSelectDto>(RequestNames.SelectFacilityTypes, filterArgs, additionalArgs);
    }

    GetFacilityType(inputModel: CustomModels.GetArgs): Observable<GeneratedModels.FacilityTypeDataDto> {
        return this.sendRequest<GeneratedModels.FacilityTypeDataDto>(RequestNames.GetFacilityType, inputModel);
    }

    AddFacilityType(inputModel: GeneratedModels.ModifyFacilityTypeDto): Observable<CustomModels.AddResult> {
        return this.sendRequest<CustomModels.AddResult>(RequestNames.AddFacilityType, inputModel);
    }

    UpdateFacilityType(inputModel: GeneratedModels.ModifyFacilityTypeDto, id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.UpdateFacilityType, inputModel, id);
    }

    DeleteFacilityType(inputModel: CustomModels.DeleteArgs): Observable<CustomModels.DeleteResult> {
        return this.sendRequest<CustomModels.DeleteResult>(RequestNames.DeleteFacilityType, inputModel);
    }

    ReorderFacilityTypes(inputModel: GeneratedModels.ReorderArgs): Observable<CustomModels.UpdateResult> {
        return this.sendRequest<CustomModels.UpdateResult>(RequestNames.ReorderFacilityTypes, inputModel);
    }

    GetFacilities(filterArgs: GeneratedModels.FacilityFilter, additionalArgs: CustomModels.ListRequestArgs): Observable<IList<GeneratedModels.FacilityListDto>> {
        return this.sendListRequest<GeneratedModels.FacilityListDto>(RequestNames.GetFacilities, filterArgs, additionalArgs);
    }

    SelectFacilities(filterArgs: GeneratedModels.FacilityFilter, additionalArgs: CustomModels.ListRequestArgs): Observable<IList<GeneratedModels.FacilitySelectDto>> {
        return this.sendListRequest<GeneratedModels.FacilitySelectDto>(RequestNames.SelectFacilities, filterArgs, additionalArgs);
    }

    GetFacility(inputModel: CustomModels.GetArgs): Observable<GeneratedModels.FacilityDataDto> {
        return this.sendRequest<GeneratedModels.FacilityDataDto>(RequestNames.GetFacility, inputModel);
    }

    AddFacility(inputModel: GeneratedModels.ModifyFacilityDto): Observable<CustomModels.AddResult> {
        return this.sendRequest<CustomModels.AddResult>(RequestNames.AddFacility, inputModel);
    }

    UpdateFacility(inputModel: GeneratedModels.ModifyFacilityDto, id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.UpdateFacility, inputModel, id);
    }

    DeleteFacility(inputModel: CustomModels.DeleteArgs): Observable<CustomModels.DeleteResult> {
        return this.sendRequest<CustomModels.DeleteResult>(RequestNames.DeleteFacility, inputModel);
    }

    GetCalendarFacilities(): Observable<IList<GeneratedModels.CalendarFacilityDto>> {
        return this.sendListRequest<GeneratedModels.CalendarFacilityDto>(RequestNames.GetCalendarFacilities, {}, {});
    }

    ReorderFacilities(inputModel: GeneratedModels.ReorderArgs): Observable<CustomModels.UpdateResult> {
        return this.sendRequest<CustomModels.UpdateResult>(RequestNames.ReorderFacilities, inputModel);
    }

    GetWorksheets(filterArgs: GeneratedModels.WorksheetFilter, additionalArgs: CustomModels.ListRequestArgs): Observable<IList<GeneratedModels.WorksheetListDto>> {
        return this.sendListRequest<GeneratedModels.WorksheetListDto>(RequestNames.GetWorksheets, filterArgs, additionalArgs);
    }

    SelectWorksheets(filterArgs: GeneratedModels.WorksheetFilter, additionalArgs: CustomModels.ListRequestArgs): Observable<IList<GeneratedModels.WorksheetSelectDto>> {
        return this.sendListRequest<GeneratedModels.WorksheetSelectDto>(RequestNames.SelectWorksheets, filterArgs, additionalArgs);
    }

    GetWorksheet(inputModel: CustomModels.GetArgs): Observable<GeneratedModels.WorksheetDataDto> {
        return this.sendRequest<GeneratedModels.WorksheetDataDto>(RequestNames.GetWorksheet, inputModel);
    }

    AddWorksheet(inputModel: GeneratedModels.ModifyWorksheetDto): Observable<CustomModels.AddResult> {
        return this.sendRequest<CustomModels.AddResult>(RequestNames.AddWorksheet, inputModel);
    }

    UpdateWorksheet(inputModel: GeneratedModels.ModifyWorksheetDto, id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.UpdateWorksheet, inputModel, id);
    }

    DeleteWorksheet(inputModel: CustomModels.DeleteArgs): Observable<CustomModels.DeleteResult> {
        return this.sendRequest<CustomModels.DeleteResult>(RequestNames.DeleteWorksheet, inputModel);
    }

    RequestNewDate(id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.RequestNewDate, {}, id);
    }

    HideNotification(id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.HideNotification, {}, id);
    }

    CreateInvoice(id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.CreateInvoice, {}, id);
    }

    DownloadInvoice(id: string): Observable<CustomModels.FileDownloadResult> {
        return this.sendDownloadRequest<CustomModels.FileDownloadResult>(RequestNames.DownloadInvoice, id);
    }


    DownloadSuzukiExport(inputModel: GeneratedModels.SuzukiExportFilter): Observable<CustomModels.FileDownloadResult> {
        return this.sendDownloadRequestWithBody<CustomModels.FileDownloadResult>(RequestNames.DownloadSuzukiExport, inputModel);
    }

    GetManagerWorksheets(): Observable<IList<GeneratedModels.WorksheetListDto>> {
        return this.sendListRequest<GeneratedModels.WorksheetListDto>(RequestNames.GetManagerWorksheets, {}, {});
    }

    GetMechanicWorksheets(): Observable<IList<GeneratedModels.WorksheetListDto>> {
        return this.sendListRequest<GeneratedModels.WorksheetListDto>(RequestNames.GetMechanicWorksheets, {}, {});
    }

    GetDeliveryWorksheets(): Observable<IList<GeneratedModels.WorksheetListDto>> {
        return this.sendListRequest<GeneratedModels.WorksheetListDto>(RequestNames.GetDeliveryWorksheets, {}, {});
    }

    GetFleetManagerWorksheets(): Observable<IList<GeneratedModels.WorksheetListDto>> {
        return this.sendListRequest<GeneratedModels.WorksheetListDto>(RequestNames.GetFleetManagerWorksheets, {}, {});
    }

    AddComment(inputModel: GeneratedModels.ModifyCommentDto): Observable<CustomModels.AddResult> {
        return this.sendUpdateRequest<CustomModels.AddResult>(RequestNames.AddComment, inputModel, inputModel.worksheetId);
    }

    GetServiceDeliveryData(inputModel: CustomModels.GetArgs): Observable<GeneratedModels.ServiceDeliveryDataDto> {
        return this.sendRequest<GeneratedModels.ServiceDeliveryDataDto>(RequestNames.GetServiceDeliveryData, inputModel);
    }

    AppointmentConfirmed(id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.AppointmentConfirmed, {}, id);
    }

    NeedsPickUp(id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.NeedsPickUp, {}, id);
    }

    PickingUpVehicle(id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.PickingUpVehicle, {}, id);
    }

    VehiclePickedUp(id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.VehiclePickedUp, {}, id);
    }

    DeliveringVehicle(id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.DeliveringVehicle, {}, id);
    }

    VehicleArrived(id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.VehicleArrived, {}, id);
    }

    UnderReview(id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.UnderReview, {}, id);
    }

    WaitingWarehousePricing(id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.WaitingWarehousePricing, {}, id);
    }

    WaitingManagerPricing(id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.WaitingManagerPricing, {}, id);
    }

    WaitingConfirmation(id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.WaitingConfirmation, {}, id);
    }

    WaitingRepairParts(id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.WaitingRepairParts, {}, id);
    }

    WaitingRepair(id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.WaitingRepair, {}, id);
    }

    WaitingCompletion(id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.WaitingCompletion, {}, id);
    }

    UnderRepair(id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.UnderRepair, {}, id);
    }

    WaitingPayment(id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.WaitingPayment, {}, id);
    }

    NeedsReturning(id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.NeedsReturning, {}, id);
    }

    ReturningVehicle(id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.ReturningVehicle, {}, id);
    }

    VehicleReturned(id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.VehicleReturned, {}, id);
    }

    Finished(id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.Finished, {}, id);
    }

    ConfirmWorksheet(id: string, inputModel: GeneratedModels.ConfirmWorksheetArgs): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.ConfirmWorksheet, inputModel, id);
    }

    PayWorksheet(id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.PayWorksheet, {}, id);
    }

    CancelWorksheet(id: string, inputModel: GeneratedModels.CancelWorksheetRequest): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.CancelWorksheet, inputModel, id);
    }

    GetCustomers(filterArgs: GeneratedModels.CustomerFilter, additionalArgs: CustomModels.ListRequestArgs): Observable<IList<GeneratedModels.CustomerListDto>> {
        return this.sendListRequest<GeneratedModels.CustomerListDto>(RequestNames.GetCustomers, filterArgs, additionalArgs);
    }

    SelectCustomers(filterArgs: GeneratedModels.CustomerFilter, additionalArgs: CustomModels.ListRequestArgs): Observable<IList<GeneratedModels.CustomerSelectDto>> {
        return this.sendListRequest<GeneratedModels.CustomerSelectDto>(RequestNames.SelectCustomers, filterArgs, additionalArgs);
    }

    GetCustomer(inputModel: CustomModels.GetArgs): Observable<GeneratedModels.CustomerDataDto> {
        return this.sendRequest<GeneratedModels.CustomerDataDto>(RequestNames.GetCustomer, inputModel);
    }

    AddCustomer(inputModel: GeneratedModels.ModifyCustomerDto): Observable<CustomModels.AddResult> {
        return this.sendRequest<CustomModels.AddResult>(RequestNames.AddCustomer, inputModel);
    }

    UpdateCustomer(inputModel: GeneratedModels.ModifyCustomerDto, id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.UpdateCustomer, inputModel, id);
    }

    DeleteCustomer(inputModel: CustomModels.DeleteArgs): Observable<CustomModels.DeleteResult> {
        return this.sendRequest<CustomModels.DeleteResult>(RequestNames.DeleteCustomer, inputModel);
    }

    GetCars(filterArgs: GeneratedModels.CarFilter, additionalArgs: CustomModels.ListRequestArgs): Observable<IList<GeneratedModels.CarListDto>> {
        return this.sendListRequest<GeneratedModels.CarListDto>(RequestNames.GetCars, filterArgs, additionalArgs);
    }

    SelectCars(filterArgs: GeneratedModels.CarFilter, additionalArgs: CustomModels.ListRequestArgs): Observable<IList<GeneratedModels.CarSelectDto>> {
        return this.sendListRequest<GeneratedModels.CarSelectDto>(RequestNames.SelectCars, filterArgs, additionalArgs);
    }

    GetCar(inputModel: CustomModels.GetArgs): Observable<GeneratedModels.FleetCarDataDto> {
        return this.sendRequest<GeneratedModels.FleetCarDataDto>(RequestNames.GetCar, inputModel);
    }

    AddCar(inputModel: GeneratedModels.ModifyCarDto): Observable<CustomModels.AddResult> {
        return this.sendRequest<CustomModels.AddResult>(RequestNames.AddCar, inputModel);
    }

    UpdateCar(inputModel: GeneratedModels.ModifyCarDto, id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.UpdateCar, inputModel, id);
    }

    DeleteCar(inputModel: CustomModels.DeleteArgs): Observable<CustomModels.DeleteResult> {
        return this.sendRequest<CustomModels.DeleteResult>(RequestNames.DeleteCar, inputModel);
    }

    GetServiceCars(filterArgs: GeneratedModels.ServiceCarFilter, additionalArgs: CustomModels.ListRequestArgs): Observable<IList<GeneratedModels.ServiceCarListDto>> {
        return this.sendListRequest<GeneratedModels.ServiceCarListDto>(RequestNames.GetServiceCars, filterArgs, additionalArgs);
    }

    SelectServiceCars(filterArgs: GeneratedModels.ServiceCarFilter, additionalArgs: CustomModels.ListRequestArgs): Observable<IList<GeneratedModels.ServiceCarSelectDto>> {
        return this.sendListRequest<GeneratedModels.ServiceCarSelectDto>(RequestNames.SelectServiceCars, filterArgs, additionalArgs);
    }

    GetServiceCar(inputModel: CustomModels.GetArgs): Observable<GeneratedModels.ServiceCarDataDto> {
        return this.sendRequest<GeneratedModels.ServiceCarDataDto>(RequestNames.GetServiceCar, inputModel);
    }

    AddServiceCar(inputModel: GeneratedModels.ModifyServiceCarDto): Observable<CustomModels.AddResult> {
        return this.sendRequest<CustomModels.AddResult>(RequestNames.AddServiceCar, inputModel);
    }

    UpdateServiceCar(inputModel: GeneratedModels.ModifyServiceCarDto, id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.UpdateServiceCar, inputModel, id);
    }

    DeleteServiceCar(inputModel: CustomModels.DeleteArgs): Observable<CustomModels.DeleteResult> {
        return this.sendRequest<CustomModels.DeleteResult>(RequestNames.DeleteServiceCar, inputModel);
    }

    GetFleetCustomers(filterArgs: GeneratedModels.FleetCustomerFilter, additionalArgs: CustomModels.ListRequestArgs): Observable<IList<GeneratedModels.FleetCustomerListDto>> {
        return this.sendListRequest<GeneratedModels.FleetCustomerListDto>(RequestNames.GetFleetCustomers, filterArgs, additionalArgs);
    }

    SelectFleetCustomers(filterArgs: GeneratedModels.FleetCustomerFilter, additionalArgs: CustomModels.ListRequestArgs): Observable<IList<GeneratedModels.FleetCustomerListDto>> {
        return this.sendListRequest<GeneratedModels.FleetCustomerListDto>(RequestNames.SelectFleetCustomers, filterArgs, additionalArgs);
    }

    GetFleetCustomer(inputModel: CustomModels.GetArgs): Observable<GeneratedModels.FleetCustomerDataDto> {
        return this.sendRequest<GeneratedModels.FleetCustomerDataDto>(RequestNames.GetFleetCustomer, inputModel);
    }

    GetServicePartners(filterArgs: GeneratedModels.ServicePartnerFilter, additionalArgs: CustomModels.ListRequestArgs): Observable<IList<GeneratedModels.ServicePartnerListDto>> {
        return this.sendListRequest<GeneratedModels.ServicePartnerListDto>(RequestNames.GetServicePartners, filterArgs, additionalArgs);
    }

    GetServicePartner(inputModel: CustomModels.GetArgs): Observable<GeneratedModels.ServicePartnerDataDto> {
        return this.sendRequest<GeneratedModels.ServicePartnerDataDto>(RequestNames.GetServicePartner, inputModel);
    }

    AddServicePartner(inputModel: GeneratedModels.ModifyServicePartnerDto): Observable<CustomModels.AddResult> {
        return this.sendRequest<CustomModels.AddResult>(RequestNames.AddServicePartner, inputModel);
    }

    DeleteServicePartner(inputModel: CustomModels.DeleteArgs): Observable<CustomModels.DeleteResult> {
        return this.sendRequest<CustomModels.DeleteResult>(RequestNames.DeleteServicePartner, inputModel);
    }

    AcceptServicePartner(id: string): Observable<CustomModels.UpdateResult> {
        return this.sendUpdateRequest<CustomModels.UpdateResult>(RequestNames.AcceptServicePartner, {}, id);
    }

    GetCalendarServicePartners(): Observable<IList<GeneratedModels.CalendarServicePartnerDto>> {
        return this.sendListRequest<GeneratedModels.CalendarServicePartnerDto>(RequestNames.GetCalendarServicePartners, {}, {});
    }

    // Should only be called from the CacheManagerService
    DynamicGetSingle(requestName: RequestNames, inputModel: CustomModels.RequestWithId): Observable<any> {
        return this.sendRequest<any>(requestName, inputModel);
    }
}

interface RequestAddParamArgs {
    url: string;
    options?: RequestOptions;
}

interface RequestOptions {
    params: HttpParams;
    body: object;
    headers: HttpHeaders;
    observe: 'body' | 'events' | 'response';
    reportProgress?: boolean;
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
}
