import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({ providedIn: 'root' })
export class StorageService {

    // region Fields

    private storage: Storage;

    private readonly authTokenKey: string = 'NDRU_AUTH_TOKEN';

    getAuthToken(): string {
        return localStorage.getItem(this.authTokenKey);
    }

    setAuthToken(value: string): void {
        localStorage.setItem(this.authTokenKey, value);
    }

    clearAuthToken(): void {
        localStorage.removeItem(this.authTokenKey);
    }

    private readonly refreshTokenKey: string = 'NDRU_REFRESH_TOKEN';

    getRefreshToken(): string {
        return localStorage.getItem(this.refreshTokenKey);
    }

    setRefreshToken(value: string): void {
        localStorage.setItem(this.refreshTokenKey, value);
    }

    clearRefreshToken(): void {
        localStorage.removeItem(this.refreshTokenKey);
    }

    private readonly lastUserNameKey: string = 'NDRU_LAST_USER_NAME';

    getLastUserName(): string | null {
        return localStorage.getItem(this.lastUserNameKey);
    }

    setLastUserName(value: string): void {
        localStorage.setItem(this.lastUserNameKey, value);
    }

    private readonly languageKey: string = 'NDRU_LANGUAGE';

    getLanguage(): string {
        return localStorage.getItem(this.languageKey);
    }

    setLanguage(value: string): void {
        localStorage.setItem(this.languageKey, value);
    }

    clearLanguage(): void {
        localStorage.removeItem(this.languageKey);
    }

    // endregion

    // region Initialization

    constructor(storage: Storage) {
        this.init(storage);
    }

    private async init(storage: Storage) {
        this.storage = await storage.create();
    }

    // endregion

    // region Methods

    async set(key: string, value: any) {
        await this.storage?.set(key, value);
    }

    get(key: string): Promise<any> {
        return this.storage?.get(key);
    }

    async remove(key: string) {
        await this.storage?.remove(key);
    }

    // endregion
}
