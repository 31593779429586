import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { Entities } from '@services/cache-manager/cache-manager.interfaces';
import { DialogService } from '@services/dialog/dialog.service';
import { NotificationService } from '@services/notification/notification.service';
import { PlatformService } from '@services/platform/platform.service';
import { UserService } from '@services/user/user.service';
import { HttpStatusCodes } from '@shared/http-status-codes';
import { Dictionary, IDictionary } from 'linq-collections';
import { AppConfigService } from '../app-config/app-config.service';
import { CacheManagerService } from '../cache-manager/cache-manager.service';
import { StorageService } from '../storage/storage.service';
import { HubEvents, RecordMessage, ServerEntities } from './hub-models';

@Injectable({ providedIn: 'root' })
export class HubService {

    // region Fields

    private readonly defaultApiEndpoint: string = '/hubs';

    private hub: HubConnection;
    private readonly hubEndpoint: string = '/main';

    private entityMappings: IDictionary<ServerEntities, Entities>;

    // endregion

    // region Initialization

    constructor(
        private location: Location,
        private navCtrl: NavController,
        private platform: PlatformService,
        private user: UserService,
        private storage: StorageService,
        private config: AppConfigService,
        private cacheManager: CacheManagerService,
        private notification: NotificationService,
        private dialog: DialogService
    ) {
        this.user.afterLoginEvent.subscribe(() => this.init());
        this.user.onLogoutEvent.subscribe(() => this.stopHub());

        this.entityMappings = new Dictionary<ServerEntities, Entities>([
            { key: ServerEntities.ServiceUser, value: Entities.UserManagement },
            { key: ServerEntities.Appointment, value: Entities.Appointments },
            { key: ServerEntities.Customer, value: Entities.Customers },
            { key: ServerEntities.ServiceCar, value: Entities.ServiceCars },
            { key: ServerEntities.Facility, value: Entities.Facilities },
            { key: ServerEntities.Worksheet, value: Entities.Worksheets }
        ]);
    }

    init() {
        let address: string = this.config.getServerAddress() + this.defaultApiEndpoint + this.hubEndpoint;
        this.hub = new HubConnectionBuilder().withUrl(address, { accessTokenFactory: () => this.user.getAuthToken() }).build();

        this.startHub(this.hub);

        this.hub.onclose(() => this.reconnectHub(this.hub));

        this.hub.on(HubEvents.RecordCreated, (result: RecordMessage) => this.recordCreated(result));
        this.hub.on(HubEvents.RecordUpdated, (result: RecordMessage) => this.recordUpdated(result));
        this.hub.on(HubEvents.RecordDeleted, (result: RecordMessage) => this.recordDeleted(result));
    }

    private startHub(hub: HubConnection, reconnect: boolean = false): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            hub.start()
                .then(() => resolve(true))
                .catch(async err => {
                    if (err.statusCode && err.statusCode == HttpStatusCodes.Unauthorized) {
                        await this.user.refreshAccessToken();
                    }
                    if (!reconnect) {
                        // this.alert.showHubStartUnsuccessfulAlert();
                    }
                    resolve(false);
                });
        });
    }

    private stopHub() {
        if (this.hub != null) {
            this.hub.stop();
            this.hub = null;
        }
    }

    // endregion

    // region Event handlers

    private reconnectHub(hub: HubConnection) {
        // Try reconnecting when the connection closes
        setTimeout(async () => {
            if (hub == null) {
                return;
            }
            let result: boolean = await this.startHub(hub, true);
            if (!result) {
                this.reconnectHub(hub);
            }
        }, 10000);
    }

    private async recordCreated(result: RecordMessage) {
        let entity = this.entityMappings.get(result.entity);
        if (this.cacheManager.isEntityCached(entity)) {
            await this.cacheManager.recordCreated(entity, result.id);
        }
        else if (result.entity == ServerEntities.Worksheet) {
            this.cacheManager.recordCreatedFilteredEvent.next({ key: Entities.Worksheets });
        }
    }

    private async recordUpdated(result: RecordMessage) {
        let entity = this.entityMappings.get(result.entity);

        if (this.cacheManager.isEntityCached(entity)) {
            await this.cacheManager.recordUpdated(entity, result.id);
        }
        else if (result.entity == ServerEntities.Worksheet) {
            this.cacheManager.recordUpdatedFilteredEvent.next({ key: Entities.Worksheets, id: result.id, record: null });
        }
    }

    private async recordDeleted(result: RecordMessage) {
        let entity = this.entityMappings.get(result.entity);

        if (this.location.path().includes(result.id)) {
            this.dialog.createRecordDeletedDialog();
        }

        if (this.cacheManager.isEntityCached(entity)) {
            await this.cacheManager.recordDeleted(entity, result.id);
        }
    }

    // endregion

    // region Methods

    // endregion
}
