import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService } from '@services/notification/notification.service';
import { PlatformService } from '@services/platform/platform.service';
import { CustomHeaders } from '@shared/custom-headers';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, take } from 'rxjs/operators';
import { UserService } from '../../user/user.service';
import { BackendService } from '../backend.service';
import { RequestNames } from '../requests';
import { InterceptorMethods } from './interceptor-methods';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

    private static readonly authTokenHeaderPrefix = 'Bearer ';

    private isRefreshingToken: boolean = false;
    private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(
        private user: UserService,
        private backend: BackendService,
        private platform: PlatformService,
        private notification: NotificationService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!InterceptorMethods.shouldIntercept(request)) {
            return next.handle(request);
        }

        let token = this.user.getAuthToken();

        return next.handle(AuthenticationInterceptor.addAuthenticationToken(request, token))
            .pipe(
                catchError(error => {
                    if (error.headers.has(CustomHeaders.TokenExpired)) {
                        if (!this.isRefreshingToken) {
                            this.isRefreshingToken = true;
                            this.refreshTokenSubject.next(null);

                            let accessToken = this.user.getAuthToken();
                            let refreshToken = this.user.getRefreshToken();

                            return this.backend.RefreshAccessToken({ token: accessToken, refreshToken: refreshToken, platform: this.platform.getDevicePlatform() })
                                .pipe(
                                    finalize(() => this.isRefreshingToken = false),
                                    catchError(() => {
                                        this.notification.showLoginExpiredInfo();
                                        this.user.logOut();
                                        return EMPTY;
                                    }),
                                    switchMap(response => {
                                        console.log('Token refresh successful!');
                                        let newToken = this.user.extractAuthToken(response.headers);
                                        this.refreshTokenSubject.next(newToken);
                                        request = AuthenticationInterceptor.addAuthenticationToken(request, newToken);
                                        return next.handle(request);
                                    })
                                );
                        }
                        else {
                            return this.refreshTokenSubject
                                .pipe(
                                    filter(result => result != null),
                                    take(1),
                                    switchMap(result => next.handle(AuthenticationInterceptor.addAuthenticationToken(request, result)))
                                );
                        }
                    }
                    else {
                        throw error;
                    }
                }),
                map((event: HttpEvent<any>) => {
                    this.extractAuthTokenIfLoggedIn(request, event);
                    return event;
                })
            );
    }

    private extractAuthTokenIfLoggedIn(request: HttpRequest<any>, event: HttpEvent<any>): void {
        if (!(event instanceof HttpResponse)) {
            return;
        }
        if (InterceptorMethods.getRequestName(request) != RequestNames.Login) {
            return;
        }

        this.user.extractAuthToken(event.headers);
    }

    private static addAuthenticationToken(request: HttpRequest<any>, authToken: string): HttpRequest<any> {
        return request.clone({headers: request.headers.set(CustomHeaders.Authorization, AuthenticationInterceptor.authTokenHeaderPrefix + authToken)});
    }
}
