import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomHeaders } from '@shared/custom-headers';
import { HttpStatusCodes, HttpStatusCodesHelper } from '@shared/http-status-codes';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '../../notification/notification.service';
import { InterceptorMethods } from './interceptor-methods';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private notification: NotificationService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!InterceptorMethods.shouldIntercept(request)) {
            return next.handle(request);
        }

        return next.handle(request)
            .pipe(
                catchError(error => this.handleHttpError(error, request))
            );
    }

    private handleHttpError(error: HttpErrorResponse, request: HttpRequest<any>): Observable<any> {
        let statusCode = HttpStatusCodesHelper.fetchFromHttpErrorResponse(error);

        if (statusCode == HttpStatusCodes.NetworkError) {
            this.notification.showNetworkError();
            return EMPTY;
        }
        else if (statusCode == HttpStatusCodes.Unauthorized && error.headers.has(CustomHeaders.TokenExpired)) {
            // If the refresh token expired, don't log the error
            return throwError(error);
        }
        else if (statusCode == HttpStatusCodes.BadRequest && error.headers.has(CustomHeaders.InvalidTimestamp)) {
            // If the timestamp is invalid, don't log the error
            return throwError(error);
        }
        else {
            ErrorInterceptor.logHttpError(request, error);
            return throwError(error);
        }
    }

    private static logHttpError(request: HttpRequest<any>, error: HttpErrorResponse) {
        let requestName = InterceptorMethods.getRequestName(request);
        console.error(`HTTP Error. For request: ${requestName}. StatusCode: ${error && error.status}. ErrorResponse:`);
        console.error(error);
    }
}
