import { Component } from '@angular/core';
import { ShowInputDialogArgs } from '@services/dialog/dialog-service.interfaces';
import { DialogService } from '@services/dialog/dialog.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-input-dialog',
    templateUrl: './input-dialog.component.html',
    styleUrls: ['./input-dialog.component.scss']
})
export class InputDialogComponent {

    // region Fields and Initialization

    title: string;
    message: string;

    input: string;

    popupVisible: boolean = true;

    close: Subject<void> = new Subject();

    constructor(
        private dialog: DialogService
    ) {
    }

    init(args: ShowInputDialogArgs) {
        this.title = args.title;
        this.message = args.message;
    }

    // endregion

    // region Event handlers

    successClicked() {
        this.dialog.inputDialogConfirmed.next(this.input);
        this.closeDialog();
    }

    closeClicked() {
        this.dialog.inputDialogConfirmed.next(null);
        this.closeDialog();
    }

    closeDialog() {
        this.popupVisible = false;
        // Give some time before the viewref is cleared for the animation to go through
        setTimeout(() => this.close.next(), 300);
    }

    onHiding() {
        this.dialog.inputDialogConfirmed.next(null);
    }

    // endregion
}
