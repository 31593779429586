import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ButtonType, ShowConfirmDialogArgs, ShowInputDialogArgs } from './dialog-service.interfaces';

@Injectable({ providedIn: 'root' })
export class DialogService {

    showConfirmDialog: Subject<ShowConfirmDialogArgs> = new Subject<ShowConfirmDialogArgs>();
    showInputDialog: Subject<ShowInputDialogArgs> = new Subject<ShowInputDialogArgs>();

    dialogConfirmed: Subject<boolean> = new Subject<boolean>();
    inputDialogConfirmed: Subject<string> = new Subject<string>();

    constructor(
        private translate: TranslateService
    ) {}

    createConfirmDeleteDialog(success: () => void, failure?: () => void): void {
        this.createGenericDialogWithHandler({
            title: this.translate.instant('common_delete_record_title'),
            message: this.translate.instant('common_delete_record_message'),
            buttonType: ButtonType.Confirm,
            confirmText: null
        }, success, failure);
    }

    createConfirmLogoutDialog(success: () => void, failure?: () => void): void {
        this.createGenericDialogWithHandler({
            title: this.translate.instant('common_confirm_logout_title'),
            message: this.translate.instant('common_confirm_logout_message'),
            buttonType: ButtonType.YesNo,
            confirmText: null
        }, success, failure);
    }

    createInvalidTimestampDialog(): void {
        this.createGenericDialog({
            title: this.translate.instant('common_invalid_timestamp_title'),
            message: this.translate.instant('common_invalid_timestamp_message'),
            buttonType: ButtonType.Ok,
            confirmText: null
        });
    }

    createRegistrationSuccessfulDialog(): void {
        this.createGenericDialog({
            title: this.translate.instant('common_registration_successful_title'),
            message: this.translate.instant('common_registration_successful_message'),
            buttonType: ButtonType.Ok,
            confirmText: null
        });
    }

    createRecordUpdatedDialog(): void {
        this.createGenericDialog({
            title: this.translate.instant('common_record_updated_title'),
            message: this.translate.instant('common_record_updated_message'),
            buttonType: ButtonType.Ok,
            confirmText: null
        });
    }

    createRecordDeletedDialog(): void {
        this.createGenericDialog({
            title: this.translate.instant('common_record_deleted_title'),
            message: this.translate.instant('common_record_deleted_message'),
            buttonType: ButtonType.Ok,
            confirmText: null
        });
    }

    createCustomMakeDialog(success: (result: string) => void, failure?: () => void): void {
        this.createInputDialog(this.translate.instant('common_custom_make_title'),
            this.translate.instant('common_custom_make_message'), success, failure);
    }

    createCustomMakeInvalidDialog(): void {
        this.createOkDialog(this.translate.instant('common_custom_make_invalid_title'),
            this.translate.instant('common_custom_make_invalid_message'));
    }

    createCustomModelDialog(success: (result: string) => void, failure?: () => void): void {
        this.createInputDialog(this.translate.instant('common_custom_model_title'),
            this.translate.instant('common_custom_model_message'), success, failure);
    }

    createCustomModelInvalidDialog(): void {
        this.createOkDialog(this.translate.instant('common_custom_model_invalid_title'),
            this.translate.instant('common_custom_model_invalid_message'));
    }

    createPriceTooLowDialog(): void {
        this.createOkDialog(this.translate.instant('common_price_too_low_title'),
            this.translate.instant('common_price_too_low_message'));
    }

    createConfirmNewDateDialog(success: () => void, failure?: () => void): void {
        this.createGenericDialogWithHandler({
            title: this.translate.instant('common_confirm_new_date_title'),
            message: this.translate.instant('common_confirm_new_date_message'),
            buttonType: ButtonType.YesNo,
            confirmText: null
        }, success, failure);
    }

    createConfirmDialog(title: string, message: string, success: () => void, failure?: () => void): void {
        this.createGenericDialogWithHandler({
            title: title,
            message: message,
            buttonType: ButtonType.Confirm,
            confirmText: null
        }, success, failure);
    }

    createCustomConfirmDialog(title: string, message: string, confirmText: string, success: () => void, failure?: () => void): void {
        this.createGenericDialogWithHandler({
            title: title,
            message: message,
            buttonType: ButtonType.Confirm,
            confirmText: confirmText
        }, success, failure);
    }

    createYesNoDialog(title: string, message: string, success: () => void, failure?: () => void): void {
        this.createGenericDialogWithHandler({
            title: title,
            message: message,
            buttonType: ButtonType.YesNo,
            confirmText: null
        }, success, failure);
    }

    createOkDialog(title: string, message: string): void {
        this.createGenericDialog({
            title: title,
            message: message,
            buttonType: ButtonType.Ok,
            confirmText: null
        });
    }

    private createGenericDialog(args: ShowConfirmDialogArgs): void {
        this.showConfirmDialog.next(args);
    }

    private createGenericDialogWithHandler(args: ShowConfirmDialogArgs, success: () => void, failure?: () => void): void {
        this.showConfirmDialog.next(args);
        this.dialogConfirmed
            .pipe(take(1))
            .subscribe(result => {
                if (result) {
                    success();
                }
                else if (failure) {
                    failure();
                }
            });
    }

    createInputDialog(title: string, message: string, success: (result: string) => void, failure?: () => void): void {
        this.showInputDialog.next({ title: title, message: message });
        this.inputDialogConfirmed
            .pipe(take(1))
            .subscribe(result => {
                if (result) {
                    success(result);
                }
                else if (failure) {
                    failure();
                }
            });
    }

}
