import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PlatformService } from '@services/platform/platform.service';

@Injectable({ providedIn: 'root' })
export class LoadingService {

    // region Fields

    private loadingController: HTMLIonLoadingElement;

    private isLoading: boolean = false;

    get showLoadingIndicator(): boolean {
        return this.isLoading;
    }

    set showLoadingIndicator(value: boolean) {
        if (value != this.isLoading) {
            this.isLoading = value;
            this.showIndicator(value);
        }
    }

    // endregion

    constructor(
        private loadCtrl: LoadingController,
        private translate: TranslateService,
        private platform: PlatformService
    ) {
    }

    async init() {
        this.loadingController = await this.loadCtrl.create({
            message: this.translate.instant('common_loading'),
            showBackdrop: this.platform.isMobile
        });
    }

    private async showIndicator(value: boolean) {
        if (this.loadingController == null) {
            await this.init();
        }
        if (value) {
            await this.loadingController.present();
        }
        else {
            await this.loadingController.dismiss();
            this.loadingController = null;
        }
    }
}
