import { registerLocaleData } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import localeDe from "@angular/common/locales/de";
import localeHu from "@angular/common/locales/hu";
import { APP_INITIALIZER, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { ComponentsModule } from "@components/components.module";
import { SelectBoxBase } from "@components/select-box/select-box-base.component";
import { SelectListBase } from "@components/select-list/select-list-base.component";
import { IonicModule, IonicRouteStrategy, iosTransitionAnimation } from "@ionic/angular";
import { IonicStorageModule } from "@ionic/storage-angular";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { AppConfigService } from "@services/app-config/app-config.service";
import { AuthenticationInterceptor } from "@services/backend/interceptors/authentication-interceptor";
import { CacheInterceptor } from "@services/backend/interceptors/cache-interceptor";
import { DateInterceptor } from "@services/backend/interceptors/date-interceptor";
import { ErrorInterceptor } from "@services/backend/interceptors/error-interceptor";
import { LanguageInterceptor } from "@services/backend/interceptors/language-interceptor";
import { ResponseTimeInterceptor } from "@services/backend/interceptors/response-time-interceptor";
import { TimeOutInterceptor } from "@services/backend/interceptors/time-out-interceptor";
import { GlobalErrorHandler } from "@services/error-handler/error-handler.service";
import { HubService } from "@services/hub/hub.service";
import { LanguageService } from "@services/language/language.service";
import { PageReuseStrategy } from "@shared/page-reuse-strategy";
import { BaseDetailPage } from "@shared/page/base-detail-page";
import { BaseListPage } from "@shared/page/base-list-page";
import { TranslateConfig } from "@shared/translate-config";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import * as Sentry from "@sentry/angular-ivy";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

Sentry.init({
    release: "service-frontend@" + environment.VERSION,
    dsn: "https://09bc5c17696201a45b7ed9795e25adb0@o4505290062757888.ingest.sentry.io/4506065004593152",
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: environment.production
                ? ["localhost", "https://server.drivendru.com/api"]
                : environment.test
                ? ["localhost", "http://server.test.drivendru.com/api"]
                : ["localhost", "http://192.168.1.67:45455/api"],
            routingInstrumentation: Sentry.routingInstrumentation,
        }),
        new Sentry.Replay({
            maskAllText: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    environment: environment.production ? "production" : environment.test ? "test" : "development",
});

// AoT requires an exported function for factories
// https://github.com/ngx-translate/core
// tslint:disable-next-line:only-arrow-functions
export function HttpLoaderFactory(http: HttpClient) {
    return TranslateConfig.HttpLoaderFactory(http);
}

// Register locale data
registerLocaleData(localeHu, "hu");
registerLocaleData(localeDe, "de");

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [AppComponent, BaseListPage, BaseDetailPage, SelectBoxBase, SelectListBase],
    imports: [
        BrowserModule,
        HttpClientModule,
        IonicModule.forRoot({
            backButtonText: "",
            swipeBackEnabled: true,
            navAnimation: iosTransitionAnimation,
            scrollAssist: false,
            scrollPadding: false,
        }),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        ComponentsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        // region HTTP_INTERCEPTORS
        // Order is important!
        { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TimeOutInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ResponseTimeInterceptor, multi: true },
        // endregion
        // Use in production
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        AppConfigService,
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [AppConfigService],
            useFactory: (appConfigService: AppConfigService) => () => appConfigService.load(),
        },
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [LanguageService],
            useFactory: (languageService: LanguageService) => () => languageService.init(),
        },
        {
            provide: LOCALE_ID,
            deps: [LanguageService],
            useFactory: (languageService: LanguageService) => languageService.language,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        { provide: RouteReuseStrategy, useClass: PageReuseStrategy },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    // tslint:disable-next-line
    constructor(hubService: HubService) {}
}

