<dx-popup [showTitle]="true"
          [title]="title"
          [width]="325"
          [height]="215"
          [visible]="popupVisible"
          (onHiding)="onHiding()">
    <div *dxTemplate="let data of 'content'">
        <div class="dialog-message">
            {{ message }}
        </div>

        <dx-text-box class="input" [(value)]="input"></dx-text-box>

        <div class="button-container">
            <dx-button [text]="'common_cancel' | translate" (onClick)="closeClicked()"></dx-button>
            <dx-button type="success" [text]="'common_confirm' | translate" (onClick)="successClicked()"></dx-button>
        </div>
    </div>
</dx-popup>
