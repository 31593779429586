import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PlatformService } from '@services/platform/platform.service';
import notify from 'devextreme/ui/notify';

@Injectable({ providedIn: 'root' })
export class NotificationService {

    constructor(
        private translate: TranslateService,
        private platform: PlatformService
    ) {}

    // region Notification

    private notification(message: string, type: string, duration: number) {
        notify({
            message: message,
            position: {
                my: 'bottom center',
                at: 'bottom center',
                offset: '0 -50'
            },
            elementAttr: { class: 'toast-' + this.platform.getPlatformDesignCode() },
            closeOnClick: true,
            onShown: e => {
                e.element.onclick = () => console.log('clicked');
            }
        }, type, duration);
    }

    private notificationSuccess(message: string, duration: number = 3000) {
        this.notification(message, 'success', duration);
    }

    private notificationError(message: string, duration: number = 3000) {
        this.notification(message, 'error', duration);
    }

    private notificationInfo(message: string, duration: number = 3000) {
        this.notification(message, 'info', duration);
    }

    private clickableNotification(message: string, type: string, duration: number, onClick: () => void) {
        notify({
            message: message,
            position: {
                my: 'bottom center',
                at: 'bottom center',
                offset: '0 -50'
            },
            elementAttr: { class: 'toast-' + this.platform.getPlatformDesignCode() },
            closeOnClick: true,
            onShown: e => e.element.onclick = onClick
        }, type, duration);
    }

    private notificationInfoClickable(message: string, onClick: () => void, duration: number = 3000) {
        this.clickableNotification(message, 'info', duration, onClick);
    }

    // endregion

    // region Show

    showGenericError(): void {
        this.notificationError(this.translate.instant('common_generic_error'));
    }

    showClientError(): void {
        this.notificationError(this.translate.instant('common_client_error'));
    }

    showNetworkError(): void {
        this.notificationError(this.translate.instant('common_network_error'));
    }

    showReadError(): void {
        this.notificationError(this.translate.instant('common_read_error'));
    }

    showCreateSuccess(): void {
        this.notificationSuccess(this.translate.instant('common_create_successful'));
    }

    showCreateError(): void {
        this.notificationError(this.translate.instant('common_create_error'));
    }

    showUpdateSuccess(): void {
        this.notificationSuccess(this.translate.instant('common_update_successful'));
    }

    showUpdateError(): void {
        this.notificationError(this.translate.instant('common_update_error'));
    }

    showDeleteSuccess(): void {
        this.notificationSuccess(this.translate.instant('common_delete_successful'));
    }

    showDeleteError(): void {
        this.notificationError(this.translate.instant('common_delete_error'));
    }

    showLoginExpiredInfo(): void {
        this.notificationInfo(this.translate.instant('common_login_expired_info'));
    }

    showForgottenPasswordSuccess(): void {
        this.notificationSuccess(this.translate.instant('common_forgotten_password_success'));
    }

    showDownloadError(): void {
        this.notificationError(this.translate.instant('common_download_error'));
    }

    showFileOpenFailedError(): void {
        this.notificationError(this.translate.instant('common_file_open_error'));
    }

    showFileSaveFailedError(): void {
        this.notificationError(this.translate.instant('common_file_save_error'));
    }

    showCustomError(message: string): void {
        this.notificationError(message);
    }

    showNotification(message: string, onClick: () => void): void {
        this.notificationInfoClickable(message, onClick);
    }
    
    showIncorrectCurrentPassword(): void {
        this.notificationError(this.translate.instant('common_incorrect_current_password'));
    }

    // endregion
}
